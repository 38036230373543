import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            // en: {
            //     translation: require('./locales/en/translation.json'),
            // },
            ko: {
                translation: require('./locales/ko/translation.json'),
            },
        },
        fallbackLng: 'ko',
        detection: {
            order: ['navigator', 'htmlTag', 'localStorage', 'path', 'subdomain'],
        },
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
