import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useFetchData = <T,>(
  fetchFunction: () => Promise<T>,
  setData: React.Dispatch<React.SetStateAction<T>>,
  setError: React.Dispatch<React.SetStateAction<string | null>>
) => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchFunction();
        setData(data);
      } catch (err: any) {
        setError(err.message);

        // Handle token expiration
        if (err.message.includes('Session expired')) {
          setError('Session expired. Please log in again.');
          navigate('/login');
        }
      }
    };

    fetchData();
  }, [fetchFunction, navigate, setData, setError]);
};

// Custom hook to fetch data by ID using a fetch function
const useFetchDataById = <T,>(
  fetchFunction: (id: string) => Promise<T>,
  id: string | undefined | null,
  setData: React.Dispatch<React.SetStateAction<T | null>>,
  setError: React.Dispatch<React.SetStateAction<string | null>>
) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data by ID
    const fetchDataById = async () => {
      if (!id) return;

      try {
        const data = await fetchFunction(id);
        setData(data);
      } catch (err: any) {
        setError(err.message);

        // Handle token expiration
        if (err.message.includes('Session expired')) {
          setError('Session expired. Please log in again.');
          navigate('/login');
        } else {
          setError('Error fetching data');
        }
      }
    };

    fetchDataById();
  }, [fetchFunction, id, navigate, setData, setError]);
};

export { useFetchData, useFetchDataById };
