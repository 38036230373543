import React, { useState } from 'react';

interface SignUpModalProps {
  onSubmit: (email: string, password: string) => void;
  onClose: () => void;
}

const SignUpModal: React.FC<SignUpModalProps> = ({ onSubmit, onClose }) => {
  const [signUpEmail, setSignUpEmail] = useState('');
  const [signUpPassword, setSignUpPassword] = useState('');

  return (
    <div style={{
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div style={{
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        width: '400px',
      }}>
        <h2>사용자등록</h2>

        <input
          type="email"
          placeholder="Email"
          value={signUpEmail}
          onChange={(e) => setSignUpEmail(e.target.value)}
          style={{ width: '90%', padding: '10px', marginBottom: '15px', border: '1px solid #ccc', borderRadius: '4px' }}
        />

        <input
          type="password"
          placeholder="Password"
          value={signUpPassword}
          onChange={(e) => setSignUpPassword(e.target.value)}
          style={{ width: '90%', padding: '10px', marginBottom: '15px', border: '1px solid #ccc', borderRadius: '4px' }}
        />

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <button
            style={{ padding: '10px 15px', border: 'none', borderRadius: '4px', backgroundColor: '#28a745', color: '#fff', cursor: 'pointer' }}
            onClick={() => onSubmit(signUpEmail, signUpPassword)}
          >
            Submit
          </button>
          <button
            style={{ padding: '10px 15px', border: 'none', borderRadius: '4px', backgroundColor: '#dc3545', color: '#fff', cursor: 'pointer' }}
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpModal;
