import React from 'react';
import NavButton from './NavButton';

interface BackButtonProps {
    route: string;
    selectedTab?: string;
    activeMaterialCategoryTab?: string;
}

const BackButton: React.FC<BackButtonProps> = ({ route, selectedTab, activeMaterialCategoryTab }) => {
    const state = { selectedTab, activeMaterialCategoryTab };

    return (
        <div className="back-button-container">
            <NavButton path={route} className="back-button" state={state}>
                &larr; Back to List
            </NavButton>
        </div>
    );
};

export default BackButton;
