import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleLogin, handleSignUp, handleLogout, handlePasswordReset } from 'src/auth/AuthService';
import { User } from 'firebase/auth';
import PasswordResetModal from 'src/components/login/PasswordResetModal';
import SignUpModal from 'src/components/login/SignUpModal';
import 'src/styles/login-page.css';

interface LoginProps {
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const LoginPage: React.FC<LoginProps> = ({ setUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const navigate = useNavigate();

  const handleLoginClick = async () => {
    setErrorMessage('');
    try {
      const user = await handleLogin(email, password, setUser);
      if (user) {
        const token = await user.getIdToken();
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        const tokenExpiryTime = decodedToken.exp * 1000;

        localStorage.setItem('authToken', token);
        localStorage.setItem('tokenExpiryTime', tokenExpiryTime.toString());
        navigate('/main');
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  const handleSignUpClick = async (email: string, password: string) => {
    try {
      await handleSignUp(email, password);
      setShowSignUpModal(false);
      alert('회원가입이 완료되었습니다! 인증 이메일이 발송되었습니다. 이메일을 인증한 후 로그인해 주세요.');
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  const handlePasswordResetClick = async (email: string) => {
    try {
      await handlePasswordReset(email);
      setShowResetPasswordModal(false);
      alert('비밀번호 재설정 이메일이 발송되었습니다.');
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      const tokenExpiryTime = localStorage.getItem('tokenExpiryTime');
      if (tokenExpiryTime) {
        const currentTime = Date.now();
        if (currentTime > parseInt(tokenExpiryTime)) {
          handleLogout(setUser, navigate);
          alert('세션이 만료되었습니다. 다시 로그인 해주세요.');
        }
      }
    };

    checkTokenExpiration();
  }, [navigate, setUser]);

  return (
    <div>
      <div className="login-container">
        <h1>로그인</h1>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {errorMessage && <div className="login-error-message">{errorMessage}</div>}
        <div className="login-button-container">
          <button className="login-button" onClick={handleLoginClick}>
            Login
          </button>
          <button className="logout-button" onClick={() => handleLogout(setUser, navigate)}>
            Logout
          </button>
        </div>
        {showSignUpModal && <SignUpModal onSubmit={handleSignUpClick} onClose={() => setShowSignUpModal(false)} />}
        {showResetPasswordModal && <PasswordResetModal onSubmit={handlePasswordResetClick} onClose={() => setShowResetPasswordModal(false)} />}
        <p className="login-text-info">
          탑머티리얼 플랫폼은 탑머티리얼 소속 사용자만 이용 가능합니다. <br /><br />
          사용자 등록을 원하시면
          <span onClick={() => setShowSignUpModal(true)}> 여기</span> 를 클릭해 등록하세요. <br />
          비밀번호에 문제가 있으시면
          <span onClick={() => setShowResetPasswordModal(true)}> 여기</span> 를 클릭해 비밀번호를 재설정 하세요. <br /><br />
          기타 문의가 있으시면
          <a href="mailto:c.roh@twinsketch.com"> c.roh@twinsketch.com</a>으로 연락부탁드립니다.
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
