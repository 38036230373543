// import './ErrorModal.css'; // Add custom styling for the modal if needed
import React, { useEffect } from 'react';
// import './ErrorModal.css'; // Custom styles for the modal

interface ErrorModalProps {
    message: string | null;
    onClose: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ message, onClose }) => {
    // Close the modal when clicking outside of it
    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            const modalContent = document.querySelector('.modal-content');
            if (modalContent && !modalContent.contains(event.target as Node)) {
                onClose();
            }
        };

        if (message) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => document.removeEventListener('mousedown', handleOutsideClick);
    }, [message, onClose]);

    // Prevent rendering if there's no message
    if (!message) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Error</h2>
                <p>{message}</p>
                <button onClick={onClose} className="close-button">Close</button>
            </div>
        </div>
    );
};

export default ErrorModal;
