import React from 'react';
import { UploadedFile } from 'src/model/file';

interface FileSectionProps {
    files: UploadedFile[];
}

const FileSection: React.FC<FileSectionProps> = ({ files }) => {
    const isImageFile = (fileName: string) => {
        const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.webp'];
        return imageExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
    };

    const isPdfFile = (fileName: string) => {
        return fileName.toLowerCase().endsWith('.pdf');
    };

    return (
        <div className="file-section">
            {files.length > 0 ? (
                <ul className="file-list">
                    {files.map((file) => (
                        <li key={file.id} className="file-item">
                            {/* File name and notes section */}
                            <div className="file-metadata">
                                <p className="file-name">{file.fileName}</p>
                                {file.notes && <p className="file-notes-detail">{file.notes}</p>}
                            </div>

                            {/* File preview */}
                            {isImageFile(file.fileName) ? (
                                <a href={file.s3Url} download={file.fileName}>
                                    <img
                                        src={file.s3Url}
                                        alt={file.fileName}
                                        className="uploaded-image"
                                    />
                                </a>
                            ) : isPdfFile(file.fileName) ? (
                                <embed
                                    src={file.s3Url}
                                    type="application/pdf"
                                    className="uploaded-pdf"
                                    style={{ width: '100%', height: '800px' }}
                                />
                            ) : (
                                <a href={file.s3Url} download className="file-link">
                                    <span className="file-icon">📄</span>
                                    {file.fileName}
                                </a>
                            )}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No files</p>
            )}
        </div>
    );
};

export default FileSection;
