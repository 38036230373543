export enum MaterialType {
  UNSPECIFIED = "Unspecified",

  ACTIVE_MATERIAL = "Active Material", // Active material used in electrodes
  CONDUCTING_AGENT = "Conducting Agent",
  BINDER = "Binder", // Material that acts as a binder
  SOLVENT = "Solvent",
  CURRENT_COLLECTOR = "Current Collector",
  UNKNOWN = "Unknown"
}

export enum ElectrodeType {
  UNSPECIFIED = "Unspecified",
  CATHODE = "Cathode",
  ANODE = "Anode",
}

export enum MaterialForm {
  UNSPECIFIED = "Unspecified",

  POWDER = "Powder",
  SOLUTION = "Solution",
  SOLVENT = "Solvent",
  FOIL = "Foil",
  UNKNOWN = "Unknown"
}

/**
 * Enum for different material category.
 */
export enum ActiveMaterialCategory {
  UNSPECIFIED = "Unspecified",

  // 양극
  NCM = 'NCM',
  LFP = 'LFP',
  LMNO = 'LMNO',
  LMFP = 'LMFP',

  // 음극
  AG = 'Aritifial Graphite',
  NG = 'Natural Graphite',
  SIO = 'SiO',
  SIC = 'SiC',
  SI_ALLOY = 'Si alloy',

  UNKNOWN = "Unknown"
}

export enum MaterialBaseType {
  UNSPECIFIED = "Unspecified",

  OIL_BASED = 'Oil Based',
  WATER_BASED = 'Water Based',

  UNKNOWN = "Unknown"
}