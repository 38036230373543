import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header from 'src/components/common/Header';
import { Material } from 'src/model/material';
import { ActiveMaterialCategory, MaterialType } from 'src/model/enums';
import ApiService from 'src/services/ApiService';
import ROUTES from 'src/constants/routes';
import { useTranslation } from 'react-i18next';
import ListHeader from 'src/components/listPage/ListHeader';
import { useAppData } from 'src/components/AppDataContext';
import ErrorModal from 'src/components/common/ErrorModal';
import { MaterialFieldConfig, materialFieldsByType } from 'src/components/dataFields/MaterialTypeFields';
import SortButton, { CombinedSortableFields } from 'src/components/common/SortButton';
import Pagination from 'src/components/Pagination';

const MaterialsList: React.FC = () => {
  const { materials, setMaterials } = useAppData();
  const { t } = useTranslation();
  const location = useLocation();

  // State Management
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useState<MaterialType | 'All'>(location.state?.selectedTab || 'All');
  const [activeMaterialCategoryTab, setActiveMaterialCategoryTab] = useState<ActiveMaterialCategory | 'All'>(location.state?.activeMaterialCategoryTab || 'All');
  const [sortBy, setSortBy] = useState<CombinedSortableFields>('createdAt'); // Use CombinedSortableFields
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [materialNameSort, setMaterialNameSort] = useState<'asc' | 'desc' | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 20;
  const totalPages = Math.ceil(totalItems / pageSize);

  // Fetch Available Types
  const availableMaterialTypes = useMemo(() => Object.values(MaterialType).filter(type => type !== MaterialType.UNSPECIFIED) as MaterialType[], []);
  const availableActiveMaterialCategoryTypes = useMemo(() => Object.values(ActiveMaterialCategory).filter(type => type !== ActiveMaterialCategory.UNSPECIFIED) as ActiveMaterialCategory[], []);

  // Fetch Materials
  const fetchData = useCallback(async () => {
    try {
      const response = await ApiService.fetchMaterials(
        sortBy,
        sortDirection,
        currentPage,
        pageSize,
        selectedTab !== 'All' ? selectedTab : undefined,
        selectedTab === MaterialType.ACTIVE_MATERIAL && activeMaterialCategoryTab !== 'All' ? activeMaterialCategoryTab : undefined
      );
      if ('data' in response && 'pagination' in response) {
        setMaterials(response.data);
        setTotalItems(response.pagination.totalItems);
      } else {
        setMaterials(response);
        setTotalItems(response.length);
      }
    } catch (fetchError) {
      console.error('Error fetching materials:', fetchError);
      setError('Failed to fetch materials');
    }
  }, [sortBy, sortDirection, currentPage, pageSize, selectedTab, activeMaterialCategoryTab]);

  useEffect(() => {
    fetchData();
  }, [fetchData, selectedTab, activeMaterialCategoryTab]);

  // Toggle sorting for materialName with 3 states: asc, desc, none
  const handleMaterialNameSort = () => {
    const nextSort = materialNameSort === 'asc' ? 'desc' : materialNameSort === 'desc' ? null : 'asc';
    setMaterialNameSort(nextSort);
    setSortBy(nextSort ? 'materialName' : 'createdAt');
    setSortDirection(nextSort || 'asc');
    setCurrentPage(1);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value.toLowerCase());

  const handleTabChange = useCallback((tab: MaterialType | 'All') => setSelectedTab(tab), []);
  const handleActiveMaterialCategoryChange = useCallback((tab: ActiveMaterialCategory | 'All') => setActiveMaterialCategoryTab(tab), []);

  // Filter and Display Materials
  const filteredMaterials = useMemo(() => {
    return materials.filter(material =>
      Object.values(material)
        .filter(field => field && typeof field === 'string')
        .some(field => field.toLowerCase().includes(searchTerm))
    );
  }, [materials, searchTerm]);

  const materialsToDisplay = useMemo(() => {
    return selectedTab === 'All' ? filteredMaterials
      : selectedTab === MaterialType.ACTIVE_MATERIAL && activeMaterialCategoryTab !== 'All'
        ? filteredMaterials.filter(
          material => material.materialType === selectedTab && material.activeMaterialCategory === activeMaterialCategoryTab)
        : filteredMaterials.filter(
          material => material.materialType === selectedTab);
  }, [filteredMaterials, selectedTab, activeMaterialCategoryTab]);

  const customFieldsForAllTab = useMemo(
    () => [
      { name: 'materialType', column: true },
      { name: 'materialName', column: true },
      { name: 'modelName', column: true },
      { name: 'supplier', column: true },
      { name: 'sizeD50', column: true },
      { name: 'createdBy', column: true },
      { name: 'updatedBy', column: true },
    ],
    []
  );

  const getTranslatedValue = (fieldName: string, fieldValue: any) => {
    switch (fieldName) {
      case 'materialType':
        return t(`materialTypeEnum.${fieldValue}`);
      case 'materialBaseType':
        return t(`MaterialBaseTypeEnum.${fieldValue}`);
      default:
        return fieldValue;
    }
  };

  const getHeaderTranslatedValue = (fieldName: string) => {
    if (['createdBy', 'updatedBy', 'createdAt', 'updatedAt'].includes(fieldName)) {
      return t(`dataCommonField.${fieldName}`);
    }
    return t(`materialDataField.${fieldName}`);
  };

  // Component to render tabs
  const TabButton = ({ label, isActive, onClick }: { label: string; isActive: boolean; onClick: () => void }) => (
    <button onClick={onClick} className={isActive ? 'active' : ''}>
      {label}
    </button>
  );

  // Render table headers based on selected tab
  const renderTableHeaders = () => {
    const fields = (selectedTab === 'All' ? customFieldsForAllTab : materialFieldsByType[selectedTab as MaterialType] || []) as MaterialFieldConfig[];

    return fields
      .filter((field) => field.column ?? false) // Default to `false` if `column` is undefined
      .map((field) => (
        <th key={field.name} onClick={field.name === 'materialName' ? handleMaterialNameSort : undefined}>
          {getHeaderTranslatedValue(field.name)}
          {field.name === 'materialName' && (
            materialNameSort === 'asc' ? ' ▲' : materialNameSort === 'desc' ? ' ▼' : ' •')}
        </th>
      ));
  };

  const renderTableDataCells = (material: Material) => {
    const fields = (selectedTab === 'All' ? customFieldsForAllTab : materialFieldsByType[selectedTab as MaterialType] || []) as MaterialFieldConfig[];

    return fields
      .filter((field) => field.column ?? false) // Default to `false` if `column` is undefined
      .map((field) => {
        const fieldValue = material[field.name as keyof Material];

        // Check if fieldValue is an array (like UploadedFile[]) and ignore it if so
        if (Array.isArray(fieldValue)) {
          return null; // Skip rendering this field
        }

        return (
          <td key={field.name}>
            {field.name === 'materialName' ? (
              <Link className='product-link' to={ROUTES.MATERIALS_DETAIL(material.id)} state={{ selectedTab, activeMaterialCategoryTab }}>
                {fieldValue as string | number | boolean}
              </Link>
            ) : (
              getTranslatedValue(field.name as keyof Material, fieldValue)
            )}
          </td>
        );
      });
  };



  return (
    <div>
      <Header />
      <ErrorModal message={error} onClose={() => setError(null)} />
      <main className="list-main-content">
        <ListHeader
          createRoute={ROUTES.MATERIALS_CREATE}
          addButtonLabel="등록"
          searchPlaceholder="물질 검색"
          searchTerm={searchTerm}
          handleSearch={handleSearch}
        />
        <div>
          <div className="tabs">
            <TabButton label="All" isActive={selectedTab === 'All'} onClick={() => handleTabChange('All')} />
            {availableMaterialTypes.map(type => (
              <TabButton key={type} label={t(`materialTypeEnum.${type}`)} isActive={selectedTab === type} onClick={() => handleTabChange(type)} />
            ))}
          </div>
          {selectedTab === MaterialType.ACTIVE_MATERIAL && (
            <div className="tabs secondary-tabs">
              <TabButton label="All" isActive={activeMaterialCategoryTab === 'All'} onClick={() => handleActiveMaterialCategoryChange('All')} />
              {availableActiveMaterialCategoryTypes.map(category => (
                <TabButton key={category} label={t(`activeMaterialCategoryEnum.${category}`)} isActive={activeMaterialCategoryTab === category} onClick={() => handleActiveMaterialCategoryChange(category)} />
              ))}
            </div>
          )}
          <SortButton
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
          />
          <table className="list-table">
            <thead>{renderTableHeaders()}</thead>
            <tbody>
              {materialsToDisplay.map(material => (
                <tr key={material.id}>{renderTableDataCells(material)}</tr>
              ))}
            </tbody>
          </table>
          <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
        </div>
      </main>
    </div>
  );
};

export default MaterialsList;
