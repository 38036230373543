import React, { useState } from 'react';

export interface PlatformUpdate {
    id: number;
    title: string;
    date: string;
    description: string;
    media?: { imageUrl?: string; text?: string; videoUrl?: string }[]; // Array of media items
}

export interface PlatformUpdate {
    id: number;
    title: string;
    date: string;
    description: string;
    media?: { imageUrl?: string; text?: string; videoUrl?: string }[]; // Array of media items
}

const PlatformUpdateModal: React.FC<{ isOpen: boolean; onClose: () => void; content: PlatformUpdate | null }> = ({ isOpen, onClose, content }) => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    if (!isOpen || !content) return null;

    return (
        <>
            {/* Main Modal */}
            <div className="modal-overlay-info" onClick={onClose}>
                <div className="modal-content-info" onClick={(e) => e.stopPropagation()}>
                    <button className="update-modal-close-button" onClick={onClose}>X</button>

                    <div className="modal-header">
                        <h2>{content.title}</h2>
                    </div>

                    <div className="modal-description">
                        <p>{content.description}</p>
                    </div>

                    {/* Map through the media array and render each item */}
                    {content.media && content.media.map((item, index) => (
                        <div key={index} className="modal-media-item">
                            {item.text && <p className="modal-text">{item.text}</p>}

                            {item.imageUrl && (
                                <div className="modal-image">
                                    <img
                                        src={item.imageUrl}
                                        alt={content.title}
                                        onClick={() => item.imageUrl && setSelectedImage(item.imageUrl)} // Add a check here
                                        style={{ cursor: 'pointer', maxWidth: '100%', height: 'auto' }}
                                    />

                                </div>
                            )}
                            {item.videoUrl && (
                                <div className="modal-video">
                                    <video controls src={item.videoUrl} width="100%" />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {/* Zoom Modal for Image */}
            {selectedImage && (
                <div className="zoom-modal-overlay" onClick={() => setSelectedImage(null)}>
                    <div className="zoom-modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="zoom-modal-close-button" onClick={() => setSelectedImage(null)}>X</button>
                        <img src={selectedImage} alt="Zoomed" style={{ width: '100%', height: 'auto' }} />
                    </div>
                </div>
            )}
        </>
    );
};

import datadiff1 from 'src/assets/updateNews/datadiff1.png';
import datadiff2 from 'src/assets/updateNews/datadiff2.png';
import sorting from 'src/assets/updateNews/sorting.png';
import sorting2 from 'src/assets/updateNews/sorting2.png';
import mainlog from 'src/assets/updateNews/mainlog.png';
import textbug from 'src/assets/updateNews/textbug.png';

import videoNavBack1025 from 'src/assets/updateNews/update3-1025.mov';

export const platformUpdatesData: PlatformUpdate[] = [
    {
        id: 6,
        title: "이전 데이터 값 조회 기능 추가",
        date: "2024-10-30",
        description: "데이터 상세보기 페이지에 수정된 데이터의 이전 버전을 조회할 수 있는 버튼이 추가되었습니다.",
        media: [
            { imageUrl: datadiff1, text: "- 데이터가 수정된 적이 없는 경우 이전 데이터 보기 버튼을 사용할 수 없습니다." },
            { imageUrl: datadiff2, text: "- 데이터에 수정 이력이 있는 경우, 버튼을 통해 바로 직전 버전의 데이터를 확인할 수 있습니다." },
        ]
    },
    {
        id: 5,
        title: "데이터 정렬 기능 추가",
        date: "2024-10-30",
        description: "재료와 물질 페이지에 정렬 기능이 추가되어 생성날짜, 수정날짜, 물질 이름, 원재료 물질명으로 데이터를 정렬할 수 있습니다.",
        media: [
            { imageUrl: sorting as string, text: "- 물질 페이지에서 물질 이름으로 정렬 기능이 추가되었습니다. '물질 이름' 옆 동그라미 아이콘을 눌러 정렬하세요." },
            { imageUrl: sorting2 as string, text: "- 재료 페이지에서는 '원재료 물질명'을 기준으로 정렬이 가능합니다." },
        ]
    },
    {
        id: 4,
        title: "플랫폼 메인 페이지 업데이트",
        date: "2024-10-30",
        description: "플랫폼 메인 페이지에 새로 생성되거나 업데이트된 데이터를 확인할 수 있는 기능이 추가되었습니다.",
        media: [
            { imageUrl: mainlog, text: "" },
        ]
    },
    {
        id: 3,
        title: "물질 상세 화면에서 이전 리스트로 돌아가기 기능 추가",
        date: "2024-10-25",
        description: "물질 상세 화면에서 리스트로 돌아가기 기능이 추가되었습니다. 이전 페이지로 돌아가기 버튼을 누르면, 열람 중이던 물질 탭을 유지하며 리스트로 돌아갈 수 있습니다.",
        media: [
            { videoUrl: videoNavBack1025, text: "" },
        ]
    },
    {
        id: 2,
        title: "설명 텍스트 버그 수정",
        date: "2024-10-25",
        description: "설명 텍스트가 올바르게 표시되지 않던 버그를 수정했습니다.",
        media: [
            { imageUrl: textbug, text: "" }
        ]
    },
];

export default PlatformUpdateModal;
