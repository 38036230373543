import { MaterialType } from 'src/model/enums';
import { Material } from 'src/model/material';

export interface MaterialFieldConfig {
  name: string;
  type: 'text' | 'number' | 'select';
  required?: boolean;
  column?: boolean;
}

export const materialFieldsByType: Record<MaterialType, MaterialFieldConfig[]> = {
  [MaterialType.ACTIVE_MATERIAL]: [
    { name: 'activeMaterialCategory', type: 'select', required: true, column: true },
    { name: 'materialName', type: 'text', required: true, column: true },
    { name: 'supplier', type: 'text', required: true, column: true },
    { name: 'modelName', type: 'text', required: true, column: true },
    { name: 'electrodeType', type: 'select', required: true },
    { name: 'materialForm', type: 'select' },

    { name: 'sizeD50', type: 'number', column: true },
    { name: 'bet', type: 'number' },
    { name: 'trueDensity', type: 'number' },
    { name: 'tapDensity', type: 'number' },
    { name: 'palletDensity', type: 'number' },

    { name: 'chargeCapacity', type: 'number', column: true },
    { name: 'dischargeCapacity', type: 'number', column: true },
    { name: 'initialEfficiency', type: 'number' },
    { name: 'upperVoltage', type: 'number', column: true },
    { name: 'lowerVoltage', type: 'number', column: true },

    { name: 'customMaterialName', type: 'text' },
    { name: 'notes', type: 'text' }
  ],
  [MaterialType.CONDUCTING_AGENT]: [
    { name: 'materialName', type: 'text', required: true, column: true },
    { name: 'supplier', type: 'text', required: true, column: true },
    { name: 'modelName', type: 'text', required: true, column: true },
    { name: 'customMaterialName', type: 'text' },
    { name: 'electrodeType', type: 'select' },
    { name: 'materialForm', type: 'select' },
    { name: 'materialBaseType', type: 'select', column: true },

    { name: 'sizeD50', type: 'number' },
    { name: 'bet', type: 'number' },
    { name: 'trueDensity', type: 'number' },
    { name: 'partialSolidContent', type: 'number', column: true },
    { name: 'totalSolidContent', type: 'number', column: true },
  ],
  [MaterialType.BINDER]: [
    { name: 'materialName', type: 'text', required: true, column: true },
    { name: 'supplier', type: 'text', required: true, column: true },
    { name: 'modelName', type: 'text', required: true, column: true },
    { name: 'customMaterialName', type: 'text' },
    { name: 'electrodeType', type: 'select' },
    { name: 'materialForm', type: 'select' },
    { name: 'materialBaseType', type: 'select', column: true },

    { name: 'trueDensity', type: 'number' },
    { name: 'swellingFactor', type: 'number' },
    { name: 'totalSolidContent', type: 'number' },

    { name: 'viscosity', type: 'number', column: true },
    { name: 'ph', type: 'number', column: true },
    { name: 'degreeOfSubstitution', type: 'number', column: true },
    { name: 'moisture', type: 'number', column: true },
  ],
  [MaterialType.SOLVENT]: [
    { name: 'materialName', type: 'text', required: true, column: true },
    { name: 'supplier', type: 'text', required: true, column: true },
    { name: 'modelName', type: 'text', required: true, column: true },
    { name: 'customMaterialName', type: 'text' },
    { name: 'electrodeType', type: 'select', column: true },
    { name: 'materialForm', type: 'select' },
    { name: 'materialBaseType', type: 'select', column: true },
  ],
  [MaterialType.CURRENT_COLLECTOR]: [
    { name: 'materialName', type: 'text', required: true, column: true },
    { name: 'supplier', type: 'text', required: true, column: true },
    { name: 'modelName', type: 'text', required: true, column: true },
    { name: 'customMaterialName', type: 'text' },
    { name: 'electrodeType', type: 'select' },
    { name: 'materialForm', type: 'select' },
    { name: 'thickness', type: 'number', column: true },
    { name: 'weightPerUnitArea', type: 'number', column: true },
  ],
  [MaterialType.UNKNOWN]: [
    { name: 'materialName', type: 'text', required: true, column: true },
    { name: 'supplier', type: 'text', required: true, column: true },
    { name: 'modelName', type: 'text', required: true, column: true },
    { name: 'customMaterialName', type: 'text' },
    { name: 'electrodeType', type: 'select' },
    { name: 'materialForm', type: 'select' },
    { name: 'materialBaseType', type: 'select' },

    { name: 'sizeD50', type: 'number' },
    { name: 'bet', type: 'number' },
    { name: 'trueDensity', type: 'number' },
    { name: 'tapDensity', type: 'number' },
    { name: 'chargeCapacity', type: 'number' },
    { name: 'dischargeCapacity', type: 'number' },
    { name: 'initialEfficiency', type: 'number' },
    { name: 'upperVoltage', type: 'number' },
    { name: 'lowerVoltage', type: 'number' },
    { name: 'thickness', type: 'number' },
    { name: 'weightPerUnitArea', type: 'number' },
  ],
  [MaterialType.UNSPECIFIED]: [],
};

export const getFieldsForMaterialType = (materialType: MaterialType): MaterialFieldConfig[] => {

  return materialFieldsByType[materialType].map(field => ({
    ...field,
    required: field.required ?? false,  // Default to false if undefined
    column: field.column ?? false       // Default to false if undefined
  }));
};
