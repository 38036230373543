import React from 'react';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    setCurrentPage: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, setCurrentPage }) => {
    const handlePageClick = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <div className="pagination">
            <button disabled={currentPage === 1} onClick={() => handlePageClick(currentPage - 1)}>Previous</button>

            {/* Page buttons */}
            {[...Array(totalPages)].map((_, index) => {
                const page = index + 1;
                return (
                    <button
                        key={page}
                        className={page === currentPage ? 'active' : ''}
                        onClick={() => handlePageClick(page)}
                    >
                        {page}
                    </button>
                );
            })}

            <button disabled={currentPage === totalPages} onClick={() => handlePageClick(currentPage + 1)}>Next</button>
        </div>
    );
};

export default Pagination;
