import 'src/styles/new-form.css';
import React from 'react';

// Define the option type to handle both string and object options
type OptionType = string | { value: string; label: string };

// Define the props interface, including 'disabled' and updated 'options'
interface FormFieldProps {
  label: string;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  options?: OptionType[]; // Allow options to be either strings or objects
  type?: 'text' | 'select' | 'number' | 'date';
  required?: boolean;
  explanation?: string;
  disabled?: boolean;
  min?: number; // Optional min attribute for number input
  max?: number; // Optional max attribute for number input
  step?: number | 'any'; // Optional step attribute for number input
}

// Reusable form field component
const FormField: React.FC<FormFieldProps> = ({
  label,
  name,
  value,
  onChange,
  options = [],
  type = 'text',
  required = false,
  explanation = '',
  disabled = false,
  min,
  max,
  step,
}) => {
  return (
    <div className="form-group">
      <label htmlFor={name} className="field-label">
        {label}{required && <span className="required-indicator">*</span>}
      </label>
      {explanation && <span className="field-explanation">{explanation}</span>}

      {type === 'select' ? (
        <select
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
          className="field-select"
        >
          <option value="">{`Select ${label}`}</option>
          {options.map(option =>
            typeof option === 'string' ? (
              <option key={option} value={option}>
                {option}
              </option>
            ) : (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            )
          )}
        </select>
      ) : (
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
          className="field-input"
          {...(type === 'number' && { min, max, step })}
        />
      )}
    </div>
  );
};

export default FormField;
