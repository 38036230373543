import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from 'src/components/common/Header';
import { MixingFormulationWithMaterials } from 'src/model/formulation';
import ApiService from 'src/services/ApiService';
import { useFetchData } from 'src/hook/CustomHook';
import { MaterialType } from 'src/model/enums';
import ROUTES from 'src/constants/routes';
import { useTranslation } from 'react-i18next';
import ListHeader from 'src/components/listPage/ListHeader';
import { useAppData } from 'src/components/AppDataContext';

const FormulationList: React.FC = () => {
  const { formulations, setFormulations } = useAppData();
  const [filteredFormulations, setFilteredFormulations] = useState<MixingFormulationWithMaterials[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useFetchData(ApiService.fetchFormulations, setFormulations, setError);

  useEffect(() => {
    setFilteredFormulations(formulations);
  }, [formulations]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = formulations.filter(formulation => {
      // Check if the formulation name matches the search term
      const matchesFormulationName = formulation.name.toLowerCase().includes(term);

      // Check if any material's materialName or modelName matches the search term
      const matchesMaterialNameOrModel = formulation.elements.some(element =>
        element.material.materialName.toLowerCase().includes(term) ||
        element.material.modelName?.toLowerCase().includes(term)
      );
      // Return true if either matches
      return matchesFormulationName || matchesMaterialNameOrModel;
    });

    setFilteredFormulations(filtered);
  };

  if (error) {
    return <div className="error"><p>{error}</p></div>;
  }

  return (
    <div>
      <Header />
      <main className="list-main-content">
        <ListHeader
          createRoute={ROUTES.FORMULATIONS_CREATE}
          addButtonLabel="등록"
          searchPlaceholder="전극 조성 검색"
          searchTerm={searchTerm}
          handleSearch={handleSearch}
        />
        <div>
          <table>
            <thead>
              <tr>
                <th>{t(`formulationDataField.name`)}</th>
                {[t(`materialTypeEnum.Active Material`),
                t(`materialTypeEnum.Conducting Agent`),
                t(`materialTypeEnum.Binder`)].map((type) => (
                  <th key={type}>{type}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredFormulations.map((formulation) => (
                <tr key={formulation.id}>
                  <td><Link to={ROUTES.FORMULATIONS_DETAIL(formulation.id)}>{formulation.name}</Link></td>
                  {[MaterialType.ACTIVE_MATERIAL, MaterialType.CONDUCTING_AGENT, MaterialType.BINDER].map((type) => {
                    const elementsOfType = formulation.elements.filter(
                      (element) => element.material.materialType === type
                    );
                    const sumRatio = elementsOfType.reduce((acc, element) => acc + element.ratio, 0); // Summing the ratios
                    return (
                      <td key={type}>
                        {elementsOfType.length > 0 ? (
                          <div className="formulation-cell">
                            <div className="formulation-details">
                              {elementsOfType.map((element) => (
                                <div key={element.materialId}>
                                  <strong>{element.material.materialName}</strong>: {element.ratio}%
                                </div>
                              ))}
                            </div>
                            <div className="sum-ratio">{sumRatio}%</div>
                          </div>
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
};

export default FormulationList; 