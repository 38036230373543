import React, { useState } from 'react';
import 'src/styles/FloatingButtonFeedback.css';
import FeedbackForm from './FeedbackForm';

const FloatingButton: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const openFeedbackForm = () => {
        setIsFeedbackFormOpen(true);
        setIsOpen(false); // Close the floating button menu when the feedback form opens
    };

    const closeFeedbackForm = () => {
        setIsFeedbackFormOpen(false);
    };

    return (
        <>
            <div className="floating-button-container">
                <button className="floating-button" onClick={toggleMenu}>
                    ?
                </button>
                {isOpen && (
                    <div className="floating-menu">
                        <button className="menu-item" onClick={openFeedbackForm}>
                            피드백 제출 하기
                        </button>
                        <a
                            href="https://docs.google.com/spreadsheets/d/1KzuOqvoDebmPCGN3oQIiMZWQos-ok0nYHFNor8C6DZU"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="menu-item"
                        >
                            피드백 현황 보기

                        </a>
                    </div>
                )}
            </div>

            {/* Feedback Form Modal */}
            {isFeedbackFormOpen && (
                <div className="modal-overlay-info" onClick={closeFeedbackForm}>
                    <div className="modal-content-info" onClick={(e) => e.stopPropagation()}>
                        <button className="close-button" onClick={closeFeedbackForm}>
                            Close
                        </button>
                        <FeedbackForm /> {/* Embed the FeedbackForm here */}
                    </div>
                </div>
            )}
        </>
    );
};

export default FloatingButton;

