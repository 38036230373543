import { BaseData } from './baseData';
import { UploadedFile } from './file';
import { ElementWithMaterialDetails } from './formulation';

export interface BaseCoatingRecipe extends BaseData {
    name: string;
    formulationId: string;
    slurryViscosity: number;
    slurrySolidContent: number;

    coatingType: CoatingType;
    coatingWidth: number; // should be top and back?
    shimSpec: string;

    mixingTankPumpSpeed: number;
    coatingPumpSpeed: number;

    slotDieGap: number;

    heatingFanSpeedSV: number;
    heatingTempSV: number;

    exhustFanSpeedSV: number;
    supplyFanSppedSV: number;

    files: UploadedFile[];
}

export interface CoatingRecipe extends BaseCoatingRecipe {
    // Additional properties specific to CoatingRecipe can be added here if needed
}

export interface CoatingRecipeWithDetails extends BaseCoatingRecipe {
    formulationName: string; // Name from formulation table
    materialsWithDetails: ElementWithMaterialDetails[];
}

export enum CoatingType {
    UNSPECIFIED = "Unspecified",
    PATTERN = "Pattern Coating",
    CONTINUE = "Continue Coating"
}
