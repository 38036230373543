import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Header from 'src/components/common/Header';
import { MixingRecipeWithDetails, MaterialDetails } from 'src/model/mixingRecipe';
import ApiService from 'src/services/ApiService';
import { useFetchDataById } from 'src/hook/CustomHook';
import { handleDeleteEntity, handleDeleteFiles, handleEdit } from 'src/hook/CommonHandler';
import { UploadedFile } from 'src/model/file';
import FileService from 'src/services/FileService';
import ROUTES from 'src/constants/routes';
import { useTranslation } from 'react-i18next';
import DetailHeader from 'src/components/detailPage/DetailHeader';
import FileSection from 'src/components/file/FileSection';
import ErrorModal from 'src/components/common/ErrorModal';

const RecipeDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [recipe, setRecipe] = useState<MixingRecipeWithDetails | null>(null);
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useFetchDataById(ApiService.fetchMixingRecipeById, id, setRecipe, setError);

  const handleDeleteRecipe = async () => {
    try {
      await handleDeleteFiles(files, id!, setError);
      await handleDeleteEntity(ApiService.deleteMixingRecipe, id!, ROUTES.MIXING_RECIPES, 'Error deleting mixing recipe', navigate, setError);
    } catch (err) {
      setError('Error occurred during mixing recipe deletion');
    }
  }

  const handleEditRecipe = () =>
    handleEdit(id!, navigate, setError, ROUTES.MIXING_RECIPES_EDIT(id!));

  useEffect(() => {
    if (recipe && recipe.files && recipe.files.length > 0) {
      FileService.getFiles(id!)
        .then((fetchedFiles) => setFiles(fetchedFiles))
        .catch((err) => setError(err.message));
    }
  }, [id, recipe]);

  if (!recipe) {
    return <div>Loading...</div>;
  }

  // Group materials by MixingType
  const binderMaterials = recipe.binderMaterials ?? [];
  const slurryMaterials = recipe.slurryMaterials ?? [];

  return (
    <div>
      <Header />
      <ErrorModal message={error} onClose={() => setError(null)} />
      <div className="detail-page">
        <DetailHeader
          title={recipe.name}
          handleEdit={handleEditRecipe}
          handleDelete={handleDeleteRecipe}
          createdAt={recipe.createdAt}
          updatedAt={recipe.updatedAt}
          createdBy={recipe.createdBy}
          updatedBy={recipe.updatedBy}
        />
        <div className="content-section">
          <div className="left-column">
            <div className="table-section">
              {error ? (
                <p>{error}</p>
              ) : recipe ? (
                <table>
                  <tbody>
                    <tr>
                      <td className="field-label">{t(`mixingRecipeDataField.type`)}:</td>
                      <td className="field-value">{recipe.type}</td>
                    </tr>
                    <tr>
                      <td className="field-label">{t(`mixingRecipeDataField.formulationName`)}:</td>
                      <td className="field-value"><Link to={`/formulations/${recipe.formulationId}`}>{recipe.formulationName}</Link></td>
                    </tr>
                    <tr>
                      <td className="field-label">{t(`mixingRecipeDataField.tankCapacity`)}:</td>
                      <td className="field-value">{recipe.tankCapacity}</td>
                    </tr>
                    <tr>
                      <td className="field-label">{t(`mixingRecipeDataField.activeMaterialKg`)}:</td>
                      <td className="field-value">{recipe.activeMaterialKg}</td>
                    </tr>
                    <tr>
                      <td className="field-label">{t(`mixingRecipeDataField.binderTargetSolidContent`)}:</td>
                      <td className="field-value">{recipe.binderTargetSolidContent}</td>
                    </tr>
                    <tr>
                      <td className="field-label">{t(`mixingRecipeDataField.slurryTargetSolidContent`)}:</td>
                      <td className="field-value">{recipe.slurryTargetSolidContent}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <div className="table-section">
              <h3>바인더 믹싱</h3>
              {binderMaterials.length > 0 ? (
                <table className="element-table">
                  <thead>
                    <tr>
                      <th>{t(`materialDataField.materialName`)}</th>
                      <th>Ratio (%)</th>
                      <th>{t(`mixingRecipeDataField.weight`)}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {binderMaterials.map((material: MaterialDetails, index: number) => (
                      <tr key={index}>
                        <td>{material.materialName}</td>
                        <td>{material.ratio}</td>
                        <td>{material.weight}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No binder materials available.</p>
              )}
            </div>
            <div className="table-section">
              <h3>슬러리 믹싱</h3>
              {slurryMaterials.length > 0 ? (
                <table className="element-table">
                  <thead>
                    <tr>
                      <th>{t(`materialDataField.materialName`)}</th>
                      <th>Ratio (%)</th>
                      <th>{t(`mixingRecipeDataField.weight`)}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {slurryMaterials.map((material: MaterialDetails, index: number) => (
                      <tr key={index}>
                        <td>{material.materialName}</td>
                        <td>{material.ratio}</td>
                        <td>{material.weight}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No slurry materials available.</p>
              )}

            </div>
          </div>
          <div className="right-column">
            <FileSection files={files} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeDetail;
