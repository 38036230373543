import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Material } from 'src/model/material';
import { MixingFormulation, BaseElement, ElementWithMaterialDetails } from 'src/model/formulation';
import { ElectrodeType, MaterialType } from 'src/model/enums';
import ApiService from 'src/services/ApiService';
import Header from 'src/components/common/Header';
import ROUTES from 'src/constants/routes';
import ErrorModal from 'src/components/common/ErrorModal';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import BackButton from 'src/components/common/BackButton';

const FormulationForm: React.FC = () => {
  const { id } = useParams<{ id?: string }>();  // `id` is optional
  const [materials, setMaterials] = useState<Material[]>([]);
  const [electrodeType, setType] = useState<ElectrodeType>(ElectrodeType.UNSPECIFIED);
  const [elements, setElements] = useState<Map<MaterialType, BaseElement[]>>(new Map());
  const [formulationName, setFormulationName] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isEditMode = !!id;  // Determine if we're in create or update mode

  useEffect(() => {
    const fetchData = async () => {
      try {
        const materials = await ApiService.fetchMaterials('materialName', 'asc');
        setMaterials(materials as Material[]);

        if (isEditMode && id) {
          const formulationResponse = await ApiService.fetchFormulationById(id);
          setFormulationName(formulationResponse.name);
          setType(formulationResponse.type);

          const elementsMap = new Map<MaterialType, BaseElement[]>();
          formulationResponse.elements.forEach((element: ElementWithMaterialDetails) => {
            const materialType = element.material.materialType;
            if (!elementsMap.has(materialType)) {
              elementsMap.set(materialType, []);
            }
            elementsMap.get(materialType)!.push({
              materialId: element.material.id,
              ratio: element.ratio,
            });
          });
          setElements(elementsMap);
        }
      } catch (err) {
        setError('Error fetching data');
      }
    };

    fetchData();
  }, [id, isEditMode]);

  const handleElementChange = (materialType: MaterialType, materialIdx: number, field: keyof BaseElement, value: any) => {
    setElements((prevElements) => {
      const updatedElements = new Map(prevElements);
      const materialList = [...(updatedElements.get(materialType) || [])];

      materialList[materialIdx] = {
        ...materialList[materialIdx],
        [field]: field === 'ratio' ? parseFloat(value) : value,
      };

      updatedElements.set(materialType, materialList);
      return updatedElements;
    });
  };

  const addElementByMaterialType = (materialType: MaterialType) => {
    const filteredMaterials = materials.filter(material => material.materialType === materialType);

    if (!filteredMaterials.length) {
      setError(`해당 물질 타입의 등록된 물질이 없습니다: ${t(`materialTypeEnum.${materialType}`)}`);
      return;
    }

    setError(null); // Clear any previous error message if the material is found

    setElements((prevElements) => {
      const updatedElements = new Map(prevElements);
      const materialList = [...(updatedElements.get(materialType) || []), { materialId: filteredMaterials[0].id, ratio: 0 }];

      updatedElements.set(materialType, materialList);
      return updatedElements;
    });
  };

  const deleteElement = (materialType: MaterialType, index: number) => {
    setElements((prevElements) => {
      const updatedElements = new Map(prevElements);
      const materialList = updatedElements.get(materialType)?.filter((_, i) => i !== index) || [];

      updatedElements.set(materialType, materialList);
      return updatedElements;
    });
  };

  const handleSubmit = async () => {
    // Validation 1: Ensure all fields are filled in
    if (!formulationName || Array.from(elements.values()).flat().length === 0) {
      setError('Please complete all fields.');
      return;
    }

    const allElements: BaseElement[] = Array.from(elements.values()).flat();

    // Validation 2: Check that the sum of all element ratios equals 100
    const totalRatio = allElements.reduce((sum, element) => sum + element.ratio, 0);
    if (totalRatio !== 100) {
      setError('The sum of all element ratios must be 100.');
      return;
    }

    // Validation 3: Ensure no duplicate elements (by materialId)
    const uniqueElementIds = new Set(allElements.map((element) => element.materialId));
    if (uniqueElementIds.size !== allElements.length) {
      setError('Elements should not be duplicated.');
      return;
    }

    // Validation 4: Ensure there is at least one ACTIVE_MATERIAL element
    const activeMaterial = allElements.find((element) => {
      const material = materials.find((mat) => mat.id === element.materialId); // Assuming `materials` contains the material data
      return material?.materialType === MaterialType.ACTIVE_MATERIAL;
    });

    if (!activeMaterial) {
      setError('There must be at least one ACTIVE_MATERIAL element.');
      return;
    }

    // Logic to set `type` based on `electrodeType` of the active material
    const activeMaterialDetails = materials.find((mat) => mat.id === activeMaterial?.materialId);
    if (activeMaterialDetails) {
      // Set electrodeType based on the active material's electrodeType
      const newElectrodeType = activeMaterialDetails.electrodeType;

      try {
        if (isEditMode && id) {
          const updatedFormulation: Omit<MixingFormulation, 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'> = {
            id,
            name: formulationName,
            type: newElectrodeType,
            elements: allElements,
          };
          await ApiService.updateFormulation(id, updatedFormulation);
          navigate(ROUTES.FORMULATIONS_DETAIL(id));
        } else {
          const newFormulation: Omit<MixingFormulation, 'createdAt' | 'updatedAt' | 'id' | 'createdBy' | 'updatedBy' | 'version' | 'active'> = {
            name: formulationName,
            type: newElectrodeType,
            elements: allElements,
          };
          const createdFormulation = await ApiService.createFormulation(newFormulation);
          navigate(ROUTES.FORMULATIONS_DETAIL(createdFormulation.id));
        }
      } catch (err) {
        setError(isEditMode ? 'Error updating formulation' : 'Error creating formulation');
      }
    } else {
      setError('Failed to determine the electrode type.');
    }
  };

  const renderOptions = (materialType: MaterialType) => {
    const elementsByType = elements.get(materialType) || [];
    const filteredMaterials = materials.filter(material => material.materialType === materialType);

    return (
      <div className="material-group">
        <div className="material-header">
          <h3>{t(`materialTypeEnum.${materialType}`)}</h3>
          <button onClick={() => addElementByMaterialType(materialType)} className="add-element-button">
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
        <div className="material-section">
          {elementsByType.map((element, index) => (
            <div key={index} className="element-row">
              <select
                onChange={(e) => handleElementChange(materialType, index, 'materialId', e.target.value)}
                value={element.materialId}
              >
                {filteredMaterials.map(material => (
                  <option key={material.id} value={material.id}>
                    {material.modelName} - {material.supplier}
                  </option>
                ))}
              </select>
              <input
                type="number"
                value={element.ratio}
                onChange={(e) => handleElementChange(materialType, index, 'ratio', e.target.value)}
                placeholder="Ratio"
                min="0"
                max="100"
              />
              <button onClick={() => deleteElement(materialType, index)}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Header />
      <BackButton route={ROUTES.FORMULATIONS} />
      <ErrorModal message={error} onClose={() => setError(null)} />
      <div className="form-container">
        <h1 className="form-title">{isEditMode ? 'Update Formulation' : 'Create Formulation'}</h1>
        <div className="form-group">
          <label className="field-label">
            {t(`formulationDataField.name`)}<span className="required-indicator">*</span>
          </label>
          <input
            type="text"
            value={formulationName}
            onChange={(e) => setFormulationName(e.target.value)}
            placeholder="Enter formulation name"
            className="field-input"
          />
        </div>
        <div className="elements-container">
          <h2>{t(`formulationDataField.elements`)}</h2>
          {renderOptions(MaterialType.ACTIVE_MATERIAL)}
          {renderOptions(MaterialType.CONDUCTING_AGENT)}
          {renderOptions(MaterialType.BINDER)}
          {renderOptions(MaterialType.UNKNOWN)}
        </div>

        <div className="submit-button-container">
          <button onClick={handleSubmit} className="submit-button">
            {isEditMode ? '수정' : '등록'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormulationForm;
