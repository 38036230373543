const ROUTES = {
  LOGIN: '/login',
  MAIN: '/main',

  RAW_MATERIALS: '/raw-materials',
  RAW_MATERIALS_CREATE: '/raw-materials/create',
  RAW_MATERIALS_DETAIL: (id: string) => `/raw-materials/${id}`,
  RAW_MATERIALS_EDIT: (id: string) => `/raw-materials/edit/${id}`,

  MATERIALS: '/materials',
  MATERIALS_CREATE: '/materials/create',
  MATERIALS_DETAIL: (id: string) => `/materials/${id}`,
  MATERIALS_EDIT: (id: string) => `/materials/edit/${id}`,

  FORMULATIONS: '/formulations',
  FORMULATIONS_CREATE: '/formulations/create',
  FORMULATIONS_DETAIL: (id: string) => `/formulations/${id}`,
  FORMULATIONS_EDIT: (id: string) => `/formulations/edit/${id}`,

  MIXING_RECIPES: '/mixing-recipes',
  MIXING_RECIPES_CREATE: '/mixing-recipes/create',
  MIXING_RECIPES_DETAIL: (id: string) => `/mixing-recipes/${id}`,
  MIXING_RECIPES_EDIT: (id: string) => `/mixing-recipes/edit/${id}`,

  COATING_RECIPES: '/coating-recipes',
  COATING_RECIPES_CREATE: '/coating-recipes/create',
  COATING_RECIPES_DETAIL: (id: string) => `/coating-recipes/${id}`,
  COATING_RECIPES_EDIT: (id: string) => `/coating-recipes/edit/${id}`,
};


export const getDetailRoute = (type: 'RAW_MATERIALS' | 'MATERIALS' | 'FORMULATIONS' | 'MIXING_RECIPES' | 'COATING_RECIPES', id: string) => {
  switch (type) {
    case 'RAW_MATERIALS':
      return ROUTES.RAW_MATERIALS_DETAIL(id);
    case 'MATERIALS':
      return ROUTES.MATERIALS_DETAIL(id);
    case 'FORMULATIONS':
      return ROUTES.FORMULATIONS_DETAIL(id);
    case 'MIXING_RECIPES':
      return ROUTES.MIXING_RECIPES_DETAIL(id);
    case 'COATING_RECIPES':
      return ROUTES.COATING_RECIPES_DETAIL(id);
    default:
      throw new Error('Invalid route type');
  }
};

export default ROUTES;