import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from 'src/components/common/Header';
import { CoatingRecipeWithDetails } from 'src/model/coatingRecipe';
import ApiService from 'src/services/ApiService';
import { useFetchData } from 'src/hook/CustomHook';
import ROUTES from 'src/constants/routes';
import { useTranslation } from 'react-i18next';
import ListHeader from 'src/components/listPage/ListHeader';

const CoatingRecipeList: React.FC = () => {
  const [recipes, setRecipes] = useState<CoatingRecipeWithDetails[]>([]);
  const [filteredRecipes, setFilteredRecipes] = useState<CoatingRecipeWithDetails[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useFetchData(ApiService.fetchCoatingRecipes, setRecipes, setError);

  useEffect(() => {
    setFilteredRecipes(recipes);
  }, [recipes]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = recipes.filter(recipe => {
      const isRecipeMatch =
        recipe.formulationName.toLowerCase().includes(term) ||
        recipe.coatingType.toLowerCase().includes(term);

      const isMaterialMatch = recipe.materialsWithDetails.some(detail => {
        const material = detail.material;
        return (
          material.materialName.toLowerCase().includes(term) ||
          material.modelName?.toLowerCase().includes(term) ||
          material.supplier?.toLowerCase().includes(term)
        );
      });

      return isRecipeMatch || isMaterialMatch;
    });

    setFilteredRecipes(filtered);
  };

  if (error) {
    return <div className="error"><p>{error}</p></div>;
  }

  return (
    <div>
      <Header />
      <main className="list-main-content">
        <ListHeader
          createRoute={ROUTES.COATING_RECIPES_CREATE}
          addButtonLabel="등록"
          searchPlaceholder="코팅 레시피 검색"
          searchTerm={searchTerm}
          handleSearch={handleSearch}
        />
        <div>
          <table>
            <thead>
              <tr>
                <th>{t(`coatingRecipeDataField.name`)}</th>
                <th>{t(`coatingRecipeDataField.formulationName`)}</th>
                <th>{t(`coatingRecipeDataField.coatingType`)}</th>
                <th>{t(`coatingRecipeDataField.coatingWidth`)}</th>
                <th>{t(`coatingRecipeDataField.mixingTankPumpSpeed`)}</th>
              </tr>
            </thead>
            <tbody>
              {filteredRecipes.map((recipe) => {
                const activeMaterials = recipe.materialsWithDetails.filter(
                  detail => detail.material.materialType === "Active Material"
                );

                return (
                  <tr key={recipe.id}>
                    <td><Link to={ROUTES.COATING_RECIPES_DETAIL(recipe.id)}>{recipe.name}</Link></td>
                    <td><Link to={ROUTES.FORMULATIONS_DETAIL(recipe.formulationId)}>{recipe.formulationName}</Link></td>
                    <td>{recipe.coatingType}</td>
                    <td>{recipe.coatingWidth}</td>
                    <td>{recipe.mixingTankPumpSpeed}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
};

export default CoatingRecipeList;
