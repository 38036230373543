import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import ApiService from 'src/services/ApiService';
import { Material } from 'src/model/material';
import { MixingFormulationWithMaterials } from 'src/model/formulation';
import { MixingRecipeWithDetails } from 'src/model/mixingRecipe';
import { useUser } from 'src/auth/UserContext';
import { isTokenValid } from 'src/auth/tokenUtils';
import { RawMaterial } from 'src/model/rawMaterial';

interface AppDataContextType {
    rawMaterials: RawMaterial[];
    materials: Material[];
    formulations: MixingFormulationWithMaterials[];
    mixingRecipes: MixingRecipeWithDetails[];
    loading: boolean;
    setRawMaterials: React.Dispatch<React.SetStateAction<RawMaterial[]>>;
    setMaterials: React.Dispatch<React.SetStateAction<Material[]>>;
    setFormulations: React.Dispatch<React.SetStateAction<MixingFormulationWithMaterials[]>>;
    setMixingRecipes: React.Dispatch<React.SetStateAction<MixingRecipeWithDetails[]>>;
}

const AppDataContext = createContext<AppDataContextType | undefined>(undefined);

export const useAppData = () => {
    const context = useContext(AppDataContext);
    if (!context) {
        throw new Error('useAppData must be used within an AppDataProvider');
    }
    return context;
};

export const AppDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [rawMaterials, setRawMaterials] = useState<RawMaterial[]>([]);
    const [materials, setMaterials] = useState<Material[]>([]);
    const [formulations, setFormulations] = useState<MixingFormulationWithMaterials[]>([]);
    const [mixingRecipes, setMixingRecipes] = useState<MixingRecipeWithDetails[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { user } = useUser();
    const isValid = isTokenValid();

    useEffect(() => {
        const fetchData = async () => {
            if (isValid) {
                try {

                    const [rawMaterialResponse, materialsResponse, formulationsData, mixingRecipesData] = await Promise.all([
                        ApiService.fetchRawMaterials(),
                        ApiService.fetchMaterials(),
                        ApiService.fetchFormulations(),
                        ApiService.fetchMixingRecipes(),
                    ]);
                    setRawMaterials(rawMaterialResponse as RawMaterial[]);
                    setMaterials(materialsResponse as Material[]);
                    setFormulations(formulationsData);
                    setMixingRecipes(mixingRecipesData);

                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchData();
    }, [user]);

    return (
        <AppDataContext.Provider value={{
            rawMaterials, materials, formulations, mixingRecipes,
            setRawMaterials, setMaterials, setFormulations, setMixingRecipes,
            loading
        }}>
            {children}
        </AppDataContext.Provider>
    );
};
