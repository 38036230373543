import React from 'react';
import { Navigate } from 'react-router-dom';
import { isTokenValid } from 'src/auth/tokenUtils';
import { AppDataProvider } from 'src/components/AppDataContext';
import ROUTES from 'src/constants/routes';

const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const isValid = isTokenValid();

    if (!isValid) {
        return <Navigate to={ROUTES.LOGIN} />;
    }

    return (
        <AppDataProvider>
            {children}
        </AppDataProvider>
    );
};

export default PrivateRoute;