import { auth } from 'src/config/firebase';
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    sendEmailVerification,
    User,
    signOut
} from 'firebase/auth';
import { Dispatch, SetStateAction } from 'react';

export const handleLogin = async (email: string, password: string, setUser: React.Dispatch<React.SetStateAction<User | null>>) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        if (!user.emailVerified) {
            alert('이메일이 인증되지 않았습니다. 인증 후 로그인해 주세요.');
            await sendEmailVerification(user);
            return null;
        }

        setUser(user);
        return user;
    } catch (error: any) {
        throw new Error('아이디 또는 비밀번호가 잘못되었습니다.');
    }
};

export const handleSignUp = async (email: string, password: string) => {
    if (
        email !== 'haezyrohs@gmail.com' &&
        email !== 'c.roh@twinsketch.com' &&
        !email.endsWith('@topmaterial.co.kr') &&
        !email.endsWith('@twinsketch.com')
    ) {
        throw new Error('허용된 이메일 주소로만 가입할 수 있습니다.');
    }

    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        // Send verification email
        await sendEmailVerification(user);
        return user;
    } catch (error: any) {
        switch (error.code) {
            case 'auth/email-already-in-use':
                throw new Error('이미 등록된 이메일입니다.');
            case 'auth/weak-password':
                throw new Error('비밀번호는 6자 이상이어야 합니다.');
            default:
                throw new Error('회원가입 중 오류가 발생했습니다. 다시 시도해 주세요.');
        }
    }
};

export const handlePasswordReset = async (email: string) => {
    try {
        await sendPasswordResetEmail(auth, email);
    } catch (error: any) {
        throw new Error('이메일 전송 중 오류가 발생했습니다.');
    }
};

export const handleLogout = async (
    setUser: Dispatch<SetStateAction<User | null>>, // Update the type here
    navigate: (path: string) => void
) => {
    try {
        await signOut(auth);
        setUser(null);

        // Remove the token and expiry time from localStorage
        localStorage.removeItem('authToken');
        localStorage.removeItem('tokenExpiryTime');

        // Navigate to login page
        navigate('/login');
    } catch (error: unknown) {
        if (error instanceof Error) {
            console.error('Error logging out:', error.message);
        } else {
            console.error('Unexpected error logging out:', error);
        }
    }
};
