import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CoatingType, CoatingRecipe } from 'src/model/coatingRecipe';
import { MixingFormulationWithMaterials } from 'src/model/formulation';
import ApiService from 'src/services/ApiService';
import FormField from 'src/components/formPage/FormField';
import { useFetchData } from 'src/hook/CustomHook';
import { FileUpload } from 'src/components/file/FileUpload';
import { UploadedFile } from 'src/model/file';
import 'src/styles/CreateFormulationPage.css';
import Header from '../../components/common/Header';
import ROUTES from 'src/constants/routes';
import { FileHandler } from 'src/hook/FileHandler';
import ErrorModal from '../../components/common/ErrorModal';
import { useTranslation } from 'react-i18next';

const CoatingRecipeFormPage: React.FC = () => {
  const { id } = useParams<{ id?: string }>();  // `id` is optional for create
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [formulations, setFormulations] = useState<MixingFormulationWithMaterials[]>([]);
  const [coatingRecipeData, setCoatingRecipeData] = useState<
    Omit<CoatingRecipe, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>>({
      name: '',
      formulationId: '',
      slurryViscosity: 0,
      slurrySolidContent: 0,
      coatingType: CoatingType.UNSPECIFIED,
      coatingWidth: 0,
      shimSpec: '',
      mixingTankPumpSpeed: 0,
      coatingPumpSpeed: 0,
      slotDieGap: 0,
      heatingFanSpeedSV: 0,
      heatingTempSV: 0,
      exhustFanSpeedSV: 0,
      supplyFanSppedSV: 0,
      files: [],
    });
  const [existingFiles, setExistingFiles] = useState<any[]>([]);  // Store existing files for update
  const [pendingFiles, setPendingFiles] = useState<any[]>([]);    // Store new files to be uploaded
  const [deletedFilesList, setDeletedFilesList] = useState<UploadedFile[]>([]);
  const [error, setError] = useState<string | null>(null);

  // Fetch formulations data
  useFetchData(ApiService.fetchFormulations, setFormulations, setError);

  useEffect(() => {
    if (id) {
      const fetchCoatingRecipe = async () => {
        try {
          const fetchedCoatingRecipe = await ApiService.fetchCoatingRecipeById(id);
          setCoatingRecipeData(fetchedCoatingRecipe);
          setExistingFiles(fetchedCoatingRecipe.files || []);
        } catch (err) {
          setError('Error fetching coating recipe details');
        }
      };
      fetchCoatingRecipe();
    }
  }, [id]);

  const handleFileChange = (newPendingFiles: any[], updatedExistingFiles: any[]) => {
    setPendingFiles(newPendingFiles);
    setExistingFiles(updatedExistingFiles);

    setCoatingRecipeData((prevData) => ({
      ...prevData,
      files: [
        ...updatedExistingFiles,
        ...newPendingFiles.map((file) => ({
          id: '',
          fileName: file.file.name,
          fileType: file.file.type,
          notes: file.notes,
          s3Url: '',
          uploadedAt: new Date().toISOString(),
        }))
      ]
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    setCoatingRecipeData(prevState => {
      let updatedValue: any = value;
      if (type === 'number') {
        updatedValue = value === '' ? undefined : Number(value);
      } else if (name === 'coatingType') {
        updatedValue = value as CoatingType;
      }

      return {
        ...prevState,
        [name]: updatedValue
      };
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const isValidForm = () => {
      const {
        name, formulationId, slurryViscosity, slurrySolidContent, coatingType, coatingWidth,
        shimSpec, mixingTankPumpSpeed, coatingPumpSpeed, slotDieGap, heatingFanSpeedSV,
        heatingTempSV, exhustFanSpeedSV, supplyFanSppedSV
      } = coatingRecipeData;

      if (
        !name || !formulationId || slurryViscosity <= 0 || slurrySolidContent <= 0 ||
        coatingType === CoatingType.UNSPECIFIED || coatingWidth <= 0 || !shimSpec ||
        mixingTankPumpSpeed <= 0 || coatingPumpSpeed <= 0 || slotDieGap <= 0 ||
        heatingFanSpeedSV <= 0 || heatingTempSV <= 0 || exhustFanSpeedSV <= 0 || supplyFanSppedSV <= 0
      ) {
        setError('Please complete all fields and ensure valid input values.');
        return false;
      }
      return true;
    };

    if (!isValidForm()) return;

    try {
      let createdOrUpdatedId: string;

      if (id) {
        // Update existing recipe
        await FileHandler.deleteFiles(deletedFilesList, id);
        const uploadedFiles = await FileHandler.uploadFilesWithId(pendingFiles, id);
        const updatedCoatingRecipe = { ...coatingRecipeData, files: [...existingFiles, ...uploadedFiles] };
        await ApiService.updateCoatingRecipe(id, updatedCoatingRecipe);
        createdOrUpdatedId = id;  // Use the existing id
      } else {
        // Create new recipe
        const uploadedFiles = await FileHandler.uploadFilesWithoutId(pendingFiles);
        const createdCoatingRecipe = await ApiService.createCoatingRecipe({ ...coatingRecipeData, files: uploadedFiles });
        createdOrUpdatedId = createdCoatingRecipe.id;  // Use the new id
        await FileHandler.moveFiles(uploadedFiles, createdCoatingRecipe.id);
      }
      // Navigate after create or update
      if (createdOrUpdatedId) {
        navigate(ROUTES.COATING_RECIPES_DETAIL(createdOrUpdatedId));
      }
    } catch (err) {
      setError(id ? 'Error updating coating recipe' : 'Error creating coating recipe');
    }
  };

  return (
    <div>
      <Header />
      <ErrorModal message={error} onClose={() => setError(null)} />
      <div className="form-container">
        <h1 className="form-title">{id ? 'Coating Recipe 수정' : '코팅 레시피 등록'}</h1>
        <form onSubmit={handleSubmit} className="coating-recipe-form">
          {/* Basic Information Section */}
          <h2 className="section-title">기본 정보</h2>
          <FormField
            label={t(`coatingRecipeDataField.name`)}
            name="name"
            value={coatingRecipeData.name}
            onChange={handleChange}
            required
            explanation={t(`coatingRecipeDataExplanation.name`)}
          />
          <FormField
            label={t(`coatingRecipeDataField.formulationId`)}
            name="formulationId"
            value={coatingRecipeData.formulationId || ''}
            onChange={handleChange}
            options={formulations.map(formulation => ({
              value: formulation.id,
              label: formulation.name
            }))}
            type="select"
            required
            explanation={t(`coatingRecipeDataExplanation.formulationId`)}
          />
          <FormField
            label={t(`coatingRecipeDataField.slurryViscosity`)}
            name="slurryViscosity"
            value={String(coatingRecipeData.slurryViscosity || '')}
            onChange={handleChange}
            type="number"
            required
            explanation={t(`coatingRecipeDataExplanation.slurryViscosity`)}
            min={0}
          />
          <FormField
            label={t(`coatingRecipeDataField.slurrySolidContent`)}
            name="slurrySolidContent"
            value={String(coatingRecipeData.slurrySolidContent || '')}
            onChange={handleChange}
            type="number"
            required
            explanation={t(`coatingRecipeDataExplanation.slurrySolidContent`)}
            min={0}
          />
          {/* Coating Specifications Section */}
          < h2 className="section-title" > 코팅 사양</h2>
          <FormField
            label={t(`coatingRecipeDataField.coatingType`)}
            name="coatingType"
            value={coatingRecipeData.coatingType}
            onChange={handleChange}
            options={Object.keys(CoatingType).map(key => ({
              value: CoatingType[key as keyof typeof CoatingType],
              label: key
            }))}
            type="select"
            required
            explanation={t(`coatingRecipeDataExplanation.coatingType`)}
          />
          <FormField
            label={t(`coatingRecipeDataField.coatingWidth`)}
            name="coatingWidth"
            value={String(coatingRecipeData.coatingWidth || '')}
            onChange={handleChange}
            type="number"
            required
            explanation={t(`coatingRecipeDataExplanation.coatingWidth`)}
            min={0}
          />
          <FormField
            label={t(`coatingRecipeDataField.shimSpec`)}
            name="shimSpec"
            value={String(coatingRecipeData.shimSpec || '')}
            onChange={handleChange}
            type="text"
            required
            explanation={t(`coatingRecipeDataExplanation.shimSpec`)}
          />

          {/* Pump Speeds Section */}
          < h2 className="section-title" > 펌프 속도</h2>
          <FormField
            label={t(`coatingRecipeDataField.mixingTankPumpSpeed`)}
            name="mixingTankPumpSpeed"
            value={String(coatingRecipeData.mixingTankPumpSpeed || '')}
            onChange={handleChange}
            type="number"
            required
            explanation={t(`coatingRecipeDataExplanation.mixingTankPumpSpeed`)}
            min={0}
          />
          <FormField
            label={t(`coatingRecipeDataField.coatingPumpSpeed`)}
            name="coatingPumpSpeed"
            value={String(coatingRecipeData.coatingPumpSpeed || '')}
            onChange={handleChange}
            type="number"
            required
            explanation={t(`coatingRecipeDataExplanation.coatingPumpSpeed`)}
            min={0}
          />

          {/* Slot Die Section */}
          < h2 className="section-title" > 슬롯 다이 설정</h2>
          <FormField
            label={t(`coatingRecipeDataField.slotDieGap`)}
            name="slotDieGap"
            value={String(coatingRecipeData.slotDieGap || '')}
            onChange={handleChange}
            type="number"
            required
            explanation={t(`coatingRecipeDataExplanation.slotDieGap`)}
            min={0}
          />

          {/* Drying Settings Section */}
          <h2 className="section-title">건조 설정</h2>
          <FormField
            label={t(`coatingRecipeDataField.heatingFanSpeedSV`)}
            name="heatingFanSpeedSV"
            value={String(coatingRecipeData.heatingFanSpeedSV || '')}
            onChange={handleChange}
            type="number"
            required
            explanation={t(`coatingRecipeDataExplanation.heatingFanSpeedSV`)}
            min={0}
          />
          <FormField
            label={t(`coatingRecipeDataField.heatingTempSV`)}
            name="heatingTempSV"
            value={String(coatingRecipeData.heatingTempSV || '')}
            onChange={handleChange}
            type="number"
            required
            explanation={t(`coatingRecipeDataExplanation.heatingTempSV`)}
            min={0}
          />

          {/* Exhaust Settings Section */}
          < h2 className="section-title" > 배기 설정</h2>
          <FormField
            label={t(`coatingRecipeDataField.exhustFanSpeedSV`)}
            name="exhustFanSpeedSV"
            value={String(coatingRecipeData.exhustFanSpeedSV || '')}
            onChange={handleChange}
            type="number"
            required
            explanation={t(`coatingRecipeDataExplanation.exhustFanSpeedSV`)}
            min={0}
          />
          <FormField
            label={t(`coatingRecipeDataField.supplyFanSppedSV`)}
            name="supplyFanSppedSV"
            value={String(coatingRecipeData.supplyFanSppedSV || '')}
            onChange={handleChange}
            type="number"
            required
            explanation={t(`coatingRecipeDataExplanation.supplyFanSppedSV`)}
            min={0}
          />
          <h4>Related Files</h4>
          <FileUpload
            onFilesChange={handleFileChange}
            existingFiles={existingFiles}
            onDeleteExistingFile={(fileId) => {
              setDeletedFilesList((prev) => [...prev, existingFiles.find((file) => file.id === fileId)]);
              setExistingFiles((prev) => prev.filter((file) => file.id !== fileId));
            }}
          />
          <button type="submit" className="submit-button">{id ? 'Update Recipe' : 'Create Recipe'}</button>
        </form>
      </div>
    </div>
  );
};

export default CoatingRecipeFormPage;
