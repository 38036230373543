import React, { useState, useMemo, useCallback, useEffect } from 'react';
import Header from 'src/components/common/Header';
import ApiService from 'src/services/ApiService';
import ROUTES from 'src/constants/routes';
import { useTranslation } from 'react-i18next';
import ListHeader from 'src/components/listPage/ListHeader';
import { useAppData } from 'src/components/AppDataContext';
import ErrorModal from 'src/components/common/ErrorModal';
import { getFieldsForRawMaterial } from 'src/components/dataFields/RawMaterialFields';
import ListTable from 'src/components/listPage/ListTable';
import Pagination from 'src/components/Pagination';
import SortButton, { CombinedSortableFields } from 'src/components/common/SortButton';

const RawMaterialList: React.FC = () => {
    const { rawMaterials, setRawMaterials } = useAppData();
    const [searchTerm, setSearchTerm] = useState('');
    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation();
    const [sortBy, setSortBy] = useState<CombinedSortableFields>('createdAt'); // Use CombinedSortableFields
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [chemicalFormulaSort, setChemicalFormulaSort] = useState<'asc' | 'desc' | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const pageSize = 20;
    const totalPages = Math.ceil(totalItems / pageSize);

    const fetchData = useCallback(async () => {
        try {
            const response = await ApiService.fetchRawMaterials(
                sortBy,
                sortDirection,
                currentPage,
                pageSize,
            );
            if ('data' in response && 'pagination' in response) {
                setRawMaterials(response.data);
                setTotalItems(response.pagination.totalItems);
            } else {
                setRawMaterials(response);
                setTotalItems(response.length);
            }
        } catch (fetchError) {
            console.error('Error fetching raw materials:', fetchError);
            setError('Failed to fetch raw materials');
        }
    }, [sortBy, sortDirection, currentPage, pageSize]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    // Toggle sorting for chemicalFormula with 3 states: asc, desc, none
    const handleChemicalFormulaSort = () => {
        const nextSort = chemicalFormulaSort === 'asc' ? 'desc' : chemicalFormulaSort === 'desc' ? null : 'asc';
        setChemicalFormulaSort(nextSort);
        setSortBy(nextSort ? 'chemicalFormula' : 'createdAt');
        setSortDirection(nextSort || 'asc');
        setCurrentPage(1);
    };

    const getRawMaterialFields = () => {
        return getFieldsForRawMaterial()
            .concat([
                { name: 'createdBy', type: 'text', column: true },
                { name: 'updatedBy', type: 'text', column: true }
            ])
            .filter(field => field.column);
    };
    const fieldsToSearch = getRawMaterialFields().map(field => field.name);
    const fieldsToDisplay = getRawMaterialFields();

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    // Memoize filtered materials to avoid re-filtering on every render
    const filteredMaterials = useMemo(() => {
        return rawMaterials.filter(rm => {
            return fieldsToSearch.some(field => {
                const fieldValue = rm[field as keyof typeof rm];
                return fieldValue?.toString().toLowerCase().includes(searchTerm);
            });
        });
    }, [rawMaterials, searchTerm]);

    return (
        <div>
            <Header />
            <ErrorModal message={error} onClose={() => setError(null)} />
            <main className="list-main-content">
                <ListHeader
                    createRoute={ROUTES.RAW_MATERIALS_CREATE}
                    addButtonLabel="등록"
                    searchPlaceholder="재료 검색"
                    searchTerm={searchTerm}
                    handleSearch={handleSearch}
                />
                <div>
                    <div className="tabs"></div>
                    <SortButton
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        sortDirection={sortDirection}
                        setSortDirection={setSortDirection}
                    />
                    <ListTable
                        fieldsToDisplay={fieldsToDisplay}
                        data={filteredMaterials}
                        detailRoute={ROUTES.RAW_MATERIALS_DETAIL}
                        translationPrefix="rawMaterialDataField"
                        t={t}
                        onSort={handleChemicalFormulaSort}
                        sortField="chemicalFormula"
                        sortDirection={chemicalFormulaSort}
                    />
                    <Pagination currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
                </div>
            </main>
        </div >
    );
};

export default RawMaterialList;
