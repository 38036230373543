import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import Header from 'src/components/common/Header';
import { Material } from 'src/model/material';
import ApiService from 'src/services/ApiService';
import { useFetchDataById } from 'src/hook/CustomHook';
import { handleDeleteEntity, handleDeleteFiles, handleEdit } from 'src/hook/CommonHandler';
import { UploadedFile } from 'src/model/file';
import FileService from 'src/services/FileService';
import ROUTES from 'src/constants/routes';
import { useTranslation } from 'react-i18next';
import DetailHeader from 'src/components/detailPage/DetailHeader';
import FileSection from 'src/components/file/FileSection';
import { getFieldsForMaterialType } from 'src/components/dataFields/MaterialTypeFields';
import ErrorModal from 'src/components/common/ErrorModal';
import { MixingFormulation } from 'src/model/formulation';
import BackButton from 'src/components/common/BackButton';

const MaterialDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [material, setMaterial] = useState<Material | null>(null);
  const [previousMaterial, setPreviousMaterial] = useState<Material | null>(null);
  const [showPreviousVersion, setShowPreviousVersion] = useState(false);
  const [associatedFormulations, setAssociatedFormulations] = useState<MixingFormulation[]>([]);
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { selectedTab, activeMaterialCategoryTab } = location.state || {};

  // Fetch material details
  useFetchDataById(ApiService.fetchMaterialById, id, setMaterial, setError);

  const isPreviousVersionAvailable = !!material?.updatedAt && !!material?.updatedBy;

  const handleDeleteMaterial = async () => {
    if (associatedFormulations.length > 0) {
      setError('Cannot delete material: It is still in use by formulation.');
      return;
    }

    try {
      if (id) {
        await handleDeleteFiles(files, id, setError);
        await handleDeleteEntity(ApiService.deleteMaterial, id, ROUTES.MATERIALS, 'Error deleting material', navigate, setError);
      }
    } catch (err) {
      setError('Error occurred during material deletion');
    }
  };

  const handleEditMaterial = () => {
    if (id) {
      handleEdit(id, navigate, setError, ROUTES.MATERIALS_EDIT(id));
    }
  };

  const fetchPreviousVersion = async () => {
    console.log(id)
    if (id) {
      try {
        const fetchedPreviousMaterial = await ApiService.fetchMaterialById(id, 'v1');
        setPreviousMaterial(fetchedPreviousMaterial);
      } catch (error) {
        setError("Error fetching previous version");
      }
    }
  };

  const togglePreviousVersion = () => {
    setShowPreviousVersion(!showPreviousVersion);
    if (!previousMaterial) {
      fetchPreviousVersion();
    }
  };

  useEffect(() => {
    if (id) {
      ApiService.fetchFormulationsByMaterialId(id)
        .then((response) => {
          // Ensure the response is an array
          const formulations = Array.isArray(response) ? response : [response];
          setAssociatedFormulations(formulations);
        })
        .catch((err) => setError(err.message));
    }
  }, [id]);

  useEffect(() => {
    if (material && material.files && material.files.length > 0 && id) {
      FileService.getFiles(id)
        .then((fetchedFiles) => setFiles(fetchedFiles))
        .catch((err) => setError(err.message));
    }
  }, [id, material]);

  if (!material) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Header />
      <BackButton
        route={ROUTES.MATERIALS}
        selectedTab={selectedTab}
        activeMaterialCategoryTab={activeMaterialCategoryTab}
      />
      <ErrorModal message={error} onClose={() => setError(null)} />
      <div className="detail-page">
        <DetailHeader
          title={material.materialName}
          handleEdit={handleEditMaterial}
          handleDelete={handleDeleteMaterial}
          createdAt={material.createdAt}
          updatedAt={material.updatedAt}
          createdBy={material.createdBy}
          updatedBy={material.updatedBy}
        />

        {/* Toggle Previous Version Switch */}
        <div className="toggle-container">
          <label className="toggle-switch">
            <input
              type="checkbox"
              onChange={togglePreviousVersion}
              checked={showPreviousVersion}
              disabled={!isPreviousVersionAvailable}
            />
            <span className="slider"></span>
          </label>
          <span className="toggle-description">
            {isPreviousVersionAvailable
              ? showPreviousVersion
                ? t('Hide Previous Version')
                : t('Show Previous Version')
              : t('No Previous Version Available')}
          </span>
        </div>

        <div className="content-section">
          <div className="left-column">
            <div className="table-section">
              <table>
                <thead>
                  <tr>
                    <th>{t('Field')}</th>
                    <th>{t('Value')}</th>
                    {showPreviousVersion && <th>{t('Previous Value')}</th>}
                  </tr>
                </thead>
                <tbody>
                  {getFieldsForMaterialType(material.materialType)
                    .filter(fieldConfig =>
                      material[fieldConfig.name as keyof Material] !== undefined &&
                      material[fieldConfig.name as keyof Material] !== null
                    )
                    .map(fieldConfig => {
                      const currentValue = material[fieldConfig.name as keyof Material];
                      const previousValue = previousMaterial?.[fieldConfig.name as keyof typeof material];

                      return (
                        <tr key={fieldConfig.name}>
                          <td className="field-label">
                            {t(`materialDataField.${fieldConfig.name}`, fieldConfig.name)}
                            <span className="info-icon" data-tooltip={t(`materialDataExplanation.${fieldConfig.name}`)}>
                              <FaInfoCircle />
                            </span>
                          </td>
                          <td className="field-value">{currentValue?.toString() ?? '-'}</td>
                          {showPreviousVersion && (
                            <td>
                              {Array.isArray(previousValue)
                                ? `${(previousValue as UploadedFile[]).length} files attached`
                                : previousValue?.toString() ?? '-'}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="related-data-section">
              <h3>{t('Related Formulation')}</h3>
              <div>
                {associatedFormulations.length > 0 ? (
                  <ul>
                    {associatedFormulations.map(formulation => (
                      <li key={formulation.id}>
                        <Link to={ROUTES.FORMULATIONS_DETAIL(formulation.id)}>
                          <span>{formulation.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>{t('No related formulation found.')}</p>
                )}
              </div>
            </div>
          </div>
          <div className="right-column">
            <FileSection files={files} />
          </div>
        </div>
      </div>
    </div >
  );
};

export default MaterialDetail;
