import React from 'react';
import { Link } from 'react-router-dom';
import 'src/styles/new-list-table.css';

interface ListTableProps {
    fieldsToDisplay: { name: string }[];
    data: any[];
    detailRoute: (id: string) => string;
    translationPrefix: string;
    t: (key: string) => string;
    onSort?: (fieldName: string) => void;
    sortField?: string;
    sortDirection?: 'asc' | 'desc' | null;
}

const ListTable: React.FC<ListTableProps> = ({
    fieldsToDisplay,
    data,
    detailRoute,
    translationPrefix,
    t,
    onSort,
    sortField,
    sortDirection,
}) => {
    const getHeaderTranslatedValue = (fieldName: string) => {
        if (['createdBy', 'updatedBy', 'createdAt', 'updatedAt'].includes(fieldName)) {
            return t(`dataCommonField.${fieldName}`);
        }
        return t(`${translationPrefix}.${fieldName}`);
    };

    return (
        <table className="list-table">
            <thead>
                <tr>
                    {fieldsToDisplay.map(field => (
                        <th
                            key={field.name}
                            onClick={field.name === 'chemicalFormula' && onSort ? () => onSort('chemicalFormula') : undefined}
                        >
                            {getHeaderTranslatedValue(field.name)}
                            {field.name === 'chemicalFormula' && (
                                sortDirection === 'asc' ? ' ▲' : sortDirection === 'desc' ? ' ▼' : ' •'
                            )}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map(item => (
                    <tr key={item.id}>
                        {fieldsToDisplay.map(field => (
                            <td key={field.name}>
                                {field.name === 'chemicalFormula' ? (
                                    <Link className="product-link" to={detailRoute(item.id)}>
                                        {(item as any)[field.name]}
                                    </Link>
                                ) : (
                                    (item as any)[field.name]
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ListTable;
