export const isTokenValid = (): boolean => {
  const token = localStorage.getItem('authToken');
  const tokenExpiryTime = localStorage.getItem('tokenExpiryTime');

  if (!token || !tokenExpiryTime) {
    return false;
  }

  const currentTime = Date.now();
  return currentTime < parseInt(tokenExpiryTime, 10);
};