import React from 'react';
import { useNavigate } from 'react-router-dom';

interface NavButtonProps {
    path: string;
    children: React.ReactNode;
    className?: string;
    state?: any;
}

const NavButton: React.FC<NavButtonProps> = ({ path, children, className, state }) => {
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (event.metaKey || event.ctrlKey) {
            window.open(path, '_blank');
        } else {
            navigate(path, { state });
        }
    };

    return (
        <button className={className} onClick={handleClick}>
            {children}
        </button>
    );
};

export default NavButton;
