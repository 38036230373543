import React, { useState } from 'react';

const FeedbackForm: React.FC = () => {
    const [loading, setLoading] = useState(true);

    const handleIframeLoad = () => {
        setLoading(false); // Once iframe is loaded, hide the loading message
    };
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
            {loading && (
                <div style={{ position: 'absolute', zIndex: 1, fontSize: '18px', color: '#555' }}>
                    Loading...
                </div>
            )}
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSeynW8VIyYBF_ZtuaF63xCdoDYBQhQHWYPKEYZNNoVeh5Kdcw/viewform?embedded=true"
                width="640"
                height="977"
                frameBorder="0"
                marginHeight={0}
                marginWidth={0}
                title="Feedback Form"
                onLoad={handleIframeLoad} // Event handler to detect when iframe is loaded
                style={loading ? { opacity: 0 } : { opacity: 1, transition: 'opacity 0.5s ease' }} // Fade-in effect
            />
        </div>
    );
};

export default FeedbackForm;
