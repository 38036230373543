export interface RawMaterialFieldConfig {
    name: string;
    type: 'text' | 'number' | 'date';
    required?: boolean;
    column?: boolean;
}

export const rawMaterialFields: RawMaterialFieldConfig[] = [
    { name: 'productName', type: 'text', required: true },
    { name: 'chemicalFormula', type: 'text', required: true, column: true },
    { name: 'purity', type: 'text', column: true },
    { name: 'manufacturer', type: 'text', column: true },
    { name: 'supplier', type: 'text', column: true },
    { name: 'receivedQuantityKg', type: 'number' },
    { name: 'receivedDate', type: 'date', column: true },
    { name: 'manufactureDate', type: 'date' },
    { name: 'lotNumber', type: 'text' },

];

export const getFieldsForRawMaterial = (): RawMaterialFieldConfig[] => {
    return rawMaterialFields.map(field => ({
        ...field,
        required: field.required ?? false,  // Default to false if undefined
        column: field.column ?? false       // Default to false if undefined
    }));
};
