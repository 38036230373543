const ENV: 'local' | 'development' | 'production' = process.env.NODE_ENV as 'local' | 'development' | 'production' || 'local';

const apiConfig = {
  local: {
    API_BASE_URL: 'https://api.topmaterial.twinsketch.com/v1/api',
  },
  development: {
    API_BASE_URL: 'http://localhost:8080/v1/api',
    // API_BASE_URL: 'https://api.topmaterial.twinsketch.com/v1/api',
  },
  production: {
    API_BASE_URL: 'https://api.topmaterial.twinsketch.com/v1/api',
  },
};

export default apiConfig[ENV];
