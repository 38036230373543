import React, { useState, useEffect, ReactElement } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Material } from 'src/model/material';
import { MaterialType, ElectrodeType, MaterialForm, ActiveMaterialCategory, MaterialBaseType } from 'src/model/enums';
import ApiService from 'src/services/ApiService';
import { getFieldsForMaterialType } from 'src/components/dataFields/MaterialTypeFields';
import Header from '../../components/common/Header';
import FormField from 'src/components/formPage/FormField';
import { UploadedFile } from 'src/model/file';
import { FileUpload } from 'src/components/file/FileUpload';
import ROUTES from 'src/constants/routes';
import { FileHandler } from 'src/hook/FileHandler';
import { useTranslation } from 'react-i18next';
import ErrorModal from 'src/components/common/ErrorModal';
import BackButton from 'src/components/common/BackButton';

const MaterialFormPage: React.FC = () => {
  const { id } = useParams<{ id?: string }>(); // `id` is optional for create
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [materialData, setMaterialData] = useState<
    Omit<Material, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>>({
      electrodeType: ElectrodeType.UNSPECIFIED,
      materialType: MaterialType.UNSPECIFIED,
      materialForm: MaterialForm.UNSPECIFIED,
      materialName: '',
      customMaterialName: '',
      supplier: '',
      modelName: '',
      sizeD50: undefined,
      bet: undefined,
      trueDensity: undefined,
      tapDensity: undefined,
      palletDensity: undefined,
      chargeCapacity: undefined,
      dischargeCapacity: undefined,
      initialEfficiency: undefined,
      upperVoltage: undefined,
      lowerVoltage: undefined,
      swellingFactor: undefined,
      partialSolidContent: undefined,
      totalSolidContent: undefined,
      viscosity: undefined,
      ph: undefined,
      degreeOfSubstitution: undefined,
      moisture: undefined,
      thickness: undefined,
      weightPerUnitArea: undefined,
      notes: '',
      files: [],
      activeMaterialCategory: ActiveMaterialCategory.UNSPECIFIED,
      materialBaseType: MaterialBaseType.UNSPECIFIED,
    });
  const [selectedMaterialType, setSelectedMaterialType] = useState<MaterialType | undefined>(undefined);
  const [existingFiles, setExistingFiles] = useState<any[]>([]);  // Store existing files for update
  const [pendingFiles, setPendingFiles] = useState<any[]>([]);    // Store new files to be uploaded
  const [deletedFilesList, setDeletedFilesList] = useState<UploadedFile[]>([]);
  const [filePreview, setFilePreview] = useState<null | string | ReactElement>(null);
  const [error, setError] = useState<string | null>(null);

  // If `id` is provided, fetch material details for update
  useEffect(() => {
    if (id) {
      const fetchMaterial = async () => {
        try {
          const fetchedMaterial = await ApiService.fetchMaterialById(id);
          setMaterialData(fetchedMaterial);
          setExistingFiles(fetchedMaterial.files || []); // Set existing files for update
          setSelectedMaterialType(fetchedMaterial.materialType);
        } catch (err) {
          setError('Error fetching material details');
        }
      };
      fetchMaterial();
    }
  }, [id]);

  const handleInputChange = (name: string, value: any) => {
    setMaterialData((prev) => ({
      ...prev,
      [name]: value,
      initialEfficiency:
        name === 'chargeCapacity' || name === 'dischargeCapacity'
          ? (prev.dischargeCapacity && prev.chargeCapacity)
            ? (prev.dischargeCapacity / prev.chargeCapacity) * 100
            : undefined
          : prev.initialEfficiency,
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    const updatedValue = type === 'number' && value ? Number(value) : value;
    handleInputChange(name, updatedValue);
    if (name === 'materialType') setSelectedMaterialType(value as MaterialType);
  };

  const handleFileChange = (newPendingFiles: any[], updatedExistingFiles: any[]) => {
    setPendingFiles(newPendingFiles);
    setExistingFiles(updatedExistingFiles);

    setMaterialData((prevData) => ({
      ...prevData,
      files: [
        ...updatedExistingFiles,
        ...newPendingFiles.map((file) => ({
          id: '',
          fileName: file.file.name,
          fileType: file.file.type,
          notes: file.notes,
          s3Url: '',
          uploadedAt: new Date().toISOString(),
        }))
      ]
    }));

    if (newPendingFiles.length > 0) {
      const latestFile = newPendingFiles[newPendingFiles.length - 1].file; // Get the last file

      const reader = new FileReader();
      reader.onload = (e) => {
        const target = e.target as FileReader;

        if (target && target.result) {
          if (latestFile.type.includes('image')) {
            setFilePreview(<img src={target.result as string} alt="Image preview" />);
          } else if (latestFile.type.includes('pdf')) {
            setFilePreview(<iframe src={target.result as string} width="100%" height="500px" title="PDF preview"></iframe>);
          } else {
            setFilePreview('Preview not available for this file type');
          }
        }
      };

      if (latestFile.type.includes('image') || latestFile.type.includes('pdf')) {
        reader.readAsDataURL(latestFile); // For images and PDFs
      } else {
        setFilePreview('Preview not available for this file type');
      }
    } else if (updatedExistingFiles.length === 0 && newPendingFiles.length === 0) {
      // If no files are attached, remove the preview
      setFilePreview(null);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      let createdOrUpdatedId: string;

      if (id) {
        // Update existing material
        await FileHandler.deleteFiles(deletedFilesList, id);
        const uploadedFiles = await FileHandler.uploadFilesWithId(pendingFiles, id);
        const updatedMaterial = { ...materialData, files: [...existingFiles, ...uploadedFiles] };
        await ApiService.updateMaterial(id, updatedMaterial);
        createdOrUpdatedId = id;
      } else {
        // Create new material
        const uploadedFiles = await FileHandler.uploadFilesWithoutId(pendingFiles);
        const createdMaterial = await ApiService.createMaterial({ ...materialData, files: uploadedFiles });
        createdOrUpdatedId = createdMaterial.id;  // Use the new id
        await FileHandler.moveFiles(uploadedFiles, createdMaterial.id);
      }
      // Navigate after create or update
      if (createdOrUpdatedId) {
        navigate(ROUTES.MATERIALS_DETAIL(createdOrUpdatedId));
      }
    } catch (error) {
      setError(id ? 'Failed to update material' : 'Failed to create material');
    }
  };

  const renderFieldsForMaterialType = () => {
    if (!selectedMaterialType) return null;
    const fields = getFieldsForMaterialType(selectedMaterialType);
    console.log(fields);
    return fields.map((field) => (
      <FormField
        key={field.name}
        label={t(`materialDataField.${field.name}`)}
        name={field.name}
        value={(materialData as any)[field.name] || ''}
        onChange={handleChange}
        type={field.type}
        options={
          field.type === 'select' ? getOptionsForEnumField(field.name) : undefined
        }
        explanation={t(`materialDataExplanation.${field.name}`)}
        required={field.required}
      />
    ));
  };

  const getOptionsForEnumField = (fieldName: string) => {
    switch (fieldName) {
      case 'activeMaterialCategory':
        return Object.values(ActiveMaterialCategory).map(type => ({
          value: type,
          label: t(`activeMaterialCategoryEnum.${type}`),
        })).filter(option => option.label && option.value != ActiveMaterialCategory.UNSPECIFIED);
      case 'materialBaseType':
        return Object.values(MaterialBaseType).map(type => ({
          value: type,
          label: t(`MaterialBaseTypeEnum.${type}`),
        })).filter(option => option.label && option.value != MaterialBaseType.UNSPECIFIED);
      case 'electrodeType':
        return Object.values(ElectrodeType).map(type => ({
          value: type,
          label: t(`electrodeTypeEnum.${type}`),
        })).filter(option => option.label && option.value != ElectrodeType.UNSPECIFIED);
      case 'materialForm':
        return Object.values(MaterialForm).map(form => ({
          value: form,
          label: t(`materialFormEnum.${form}`),
        })).filter(option => option.label && option.value !== MaterialForm.UNSPECIFIED);
      default:
        return [];
    }
  };

  return (
    <div>
      <Header />
      <BackButton route={ROUTES.MATERIALS} />
      <ErrorModal message={error} onClose={() => setError(null)} />
      <div className="form-page">
        <h1 className="form-title">{id ? 'Update Material' : 'Create Material'}</h1>
        <div className="form-content">

          <form onSubmit={handleSubmit} className="raw-material-form">
            <FormField
              label={t(`materialDataField.materialType`)}
              name="materialType"
              value={materialData.materialType || ''}
              onChange={handleChange}
              options={Object.values(MaterialType).map(type => ({
                value: type,
                label: t(`materialTypeEnum.${type}`)
              })).filter(option => option.label && option.value !== MaterialType.UNSPECIFIED)}
              type="select"
              required
              explanation={t(`materialDataExplanation.materialType`)}
            />
            {selectedMaterialType && renderFieldsForMaterialType()}
            <button type="submit" className="submit-button">
              {id ? 'Update' : 'Create'}
            </button>
          </form>

          {/* File Upload Section */}
          <div className="file-upload-preview">
            <label className="field-label">Related Files</label>
            <FileUpload
              onFilesChange={handleFileChange}
              existingFiles={existingFiles}
              onDeleteExistingFile={(fileId) => {
                setDeletedFilesList((prev) => [...prev, existingFiles.find((file) => file.id === fileId)]);
                setExistingFiles((prev) => prev.filter((file) => file.id !== fileId));
              }}
            />
            {filePreview && (
              <div className="file-preview">
                <h4>File Preview</h4>
                {filePreview}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialFormPage;
