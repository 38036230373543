import React from 'react';
import { Link } from 'react-router-dom';

interface ListHeaderProps {
    createRoute: string;
    addButtonLabel: string;
    searchPlaceholder: string;
    searchTerm: string;
    handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ListHeader: React.FC<ListHeaderProps> = ({
    createRoute,
    addButtonLabel,
    searchPlaceholder,
    searchTerm,
    handleSearch,
}) => {
    return (
        <div className="list-content-header">
            <div className="button-container">
                <Link to={createRoute} className="add-data-button">
                    <span className="icon">➕</span> {addButtonLabel}
                </Link>
            </div>
            <div className="toolbar">
                <input
                    type="text"
                    placeholder={searchPlaceholder}
                    value={searchTerm}
                    onChange={handleSearch}
                    className="search-bar"
                />
            </div>
        </div>
    );
};

export default ListHeader;
