import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Header from 'src/components/common/Header';
import { MixingFormulationWithMaterials } from 'src/model/formulation';
import { MixingRecipe } from 'src/model/mixingRecipe';
import ApiService from 'src/services/ApiService';
import { useFetchDataById } from 'src/hook/CustomHook';
import { handleDeleteEntity, handleEdit } from 'src/hook/CommonHandler';
import { MaterialType } from 'src/model/enums';
import ROUTES from 'src/constants/routes';
import { useTranslation } from 'react-i18next';
import DetailHeader from 'src/components/detailPage/DetailHeader';
import ErrorModal from 'src/components/common/ErrorModal';
import BackButton from 'src/components/common/BackButton';

const FormulationDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [formulation, setFormulation] = useState<MixingFormulationWithMaterials | null>(null);
  const [associatedRecipes, setAssociatedRecipes] = useState<MixingRecipe[]>([]);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Fetch formulation details
  useFetchDataById(ApiService.fetchFormulationById, id, setFormulation, setError);

  // Fetch all recipes
  useEffect(() => {
    ApiService.fetchMixingRecipesByFormulationId(id!)
      .then(setAssociatedRecipes)
      .catch((err) => setError(err.message));
  }, []);

  const handleDeleteFormulation = () => {
    if (associatedRecipes.length > 0) {
      setError('Cannot delete formulation: It is still in use by recipes.');
      return;
    }
    handleDeleteEntity(ApiService.deleteFormulation, id!, ROUTES.FORMULATIONS, 'Error deleting formulation', navigate, setError);
  }

  const handleEditFormulation = () =>
    handleEdit(id!, navigate, setError, ROUTES.FORMULATIONS_EDIT(id!));

  if (!formulation) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Header />
      <BackButton route={ROUTES.FORMULATIONS} />
      <ErrorModal message={error} onClose={() => setError(null)} />
      <div className="detail-page">
        <DetailHeader
          title={formulation.name}
          handleEdit={handleEditFormulation}
          handleDelete={handleDeleteFormulation}
          createdAt={formulation.createdAt}
          updatedAt={formulation.updatedAt}
          createdBy={formulation.createdBy}
          updatedBy={formulation.updatedBy}
        />
        <div className="content-section">
          <div className="left-column">
            <p><strong>{t(`formulationDataField.type`)}:</strong> {t(`electrodeTypeEnum.${formulation?.type}`)}</p>
            {/* Group and display elements by material type */}
            {[MaterialType.ACTIVE_MATERIAL, MaterialType.CONDUCTING_AGENT, MaterialType.BINDER].map((type) => {
              const elementsOfType = formulation?.elements.filter(
                (element) => element.material.materialType === type
              );
              return (
                elementsOfType.length > 0 && (
                  <div key={type} className="element-section">
                    <h3>{t(`materialTypeEnum.${type}`)}</h3>
                    <ul className="element-list">
                      {elementsOfType.map((element, index) => (
                        <li key={index} className="element-item">
                          {/* Link to material detail page */}
                          <Link to={ROUTES.MATERIALS_DETAIL(element.materialId)} className="material-link">
                            <span className="material-name">{element.material.materialName}</span>
                          </Link>
                          <span className="material-model">{element.material.modelName}</span>
                          <span className="material-ratio">{element.ratio}%</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              );
            })}
            <div className="related-data-section">
              <h3>{t('Related Recipes')}</h3>
              <div>
                {associatedRecipes.length > 0 ? (
                  <ul className="recipe-list">
                    {associatedRecipes.map((recipe) => (
                      <li key={recipe.id}>
                        <Link to={ROUTES.MIXING_RECIPES_DETAIL(recipe.id)}>
                          <span className="recipe-name">{recipe.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No related recipes found.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormulationDetail;
