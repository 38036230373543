import React from 'react';
import { MaterialSortableFields } from 'src/model/material';
import { RawMaterialSortableFields } from 'src/model/rawMaterial'; // Assuming RawMaterialSortableFields is exported from this path

// Define a union type for sortable fields
export type CombinedSortableFields = MaterialSortableFields | RawMaterialSortableFields;

// Define the props type for SortButton
interface SortButtonProps {
    sortBy: CombinedSortableFields;
    setSortBy: React.Dispatch<React.SetStateAction<CombinedSortableFields>>;
    sortDirection: 'asc' | 'desc';
    setSortDirection: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
}

const SortButton: React.FC<SortButtonProps> = ({ sortBy, setSortBy, sortDirection, setSortDirection }) => {
    const toggleSortField = () => {
        const nextSortBy: CombinedSortableFields = sortBy === 'createdAt' ? 'updatedAt' : 'createdAt';
        setSortBy(nextSortBy);
        setSortDirection('asc'); // Default to ascending when switching fields
    };

    const handleSortFieldChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSortBy(event.target.value as CombinedSortableFields);
    };

    const toggleSortDirection = () => {
        setSortDirection(prevDirection => (prevDirection === 'asc' ? 'desc' : 'asc'));
    };

    return (
        <div className="sort-controls">
            <label htmlFor="sortField">Sort by:</label>
            <select id="sortField" value={sortBy} onChange={handleSortFieldChange}>
                <option value="createdAt">Created At</option>
                <option value="updatedAt">Updated At</option>
                {/* Add other options as needed */}
            </select>
            <button onClick={toggleSortDirection}>
                {sortDirection === 'asc' ? '▲ Ascending' : '▼ Descending'}
            </button>
        </div>
    );
};

export default SortButton;
