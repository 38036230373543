import React, { useState, useEffect, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ErrorModal from "src/components/common/ErrorModal";
import Header from "src/components/common/Header";
import ApiService from 'src/services/ApiService';
import ROUTES from 'src/constants/routes';
import { RawMaterial } from "src/model/rawMaterial";
import { FileUpload } from "src/components/file/FileUpload";
import { UploadedFile } from "src/model/file";
import { getFieldsForRawMaterial } from "src/components/dataFields/RawMaterialFields";
import FormField from "src/components/formPage/FormField";
import { FileHandler } from "src/hook/FileHandler";
import BackButton from "src/components/common/BackButton";

const RawMaterialForm: React.FC = () => {

    const [rawMaterial, setRawMaterial] = useState<
        Omit<RawMaterial, 'id' | 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy' | 'version' | 'active'>>({
            productName: '',
            chemicalFormula: '',
            purity: undefined,
            manufacturer: '',
            supplier: '',
            receivedQuantityKg: undefined,
            receivedDate: '',
            manufactureDate: '',
            lotNumber: '',
            files: [],
        });
    const { id } = useParams<{ id?: string }>();

    const [existingFiles, setExistingFiles] = useState<any[]>([]);  // Store existing files for update
    const [pendingFiles, setPendingFiles] = useState<any[]>([]);    // Store new files to be uploaded
    const [deletedFilesList, setDeletedFilesList] = useState<UploadedFile[]>([]);
    const [filePreview, setFilePreview] = useState<null | string | ReactElement>(null);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [error, setError] = useState<string | null>(null);
    const isEditMode = !!id;

    // Fetch raw material data if in edit mode
    useEffect(() => {
        if (isEditMode && id) {
            const fetchRawMaterial = async () => {
                try {
                    const response = await ApiService.fetchRawMaterialById(id);
                    setRawMaterial({
                        productName: response.productName || '',
                        chemicalFormula: response.chemicalFormula || '',
                        purity: response.purity || undefined,
                        manufacturer: response.manufacturer || '',
                        supplier: response.supplier || '',
                        receivedQuantityKg: response.receivedQuantityKg || undefined,
                        receivedDate: response.receivedDate || '',
                        manufactureDate: response.manufactureDate || '',
                        lotNumber: response.lotNumber || '',
                        files: response.files || [],
                    });
                    setExistingFiles(response.files || []);
                } catch (error) {
                    setError('Failed to load raw material data');
                }
            };
            fetchRawMaterial();
        }
    }, [id, isEditMode]);

    const handleFileChange = (newPendingFiles: any[], updatedExistingFiles: any[]) => {
        setPendingFiles(newPendingFiles);
        setExistingFiles(updatedExistingFiles);

        setRawMaterial((prevData) => ({
            ...prevData,
            files: [
                ...updatedExistingFiles,
                ...newPendingFiles.map((file) => ({
                    id: '',
                    fileName: file.file.name,
                    fileType: file.file.type,
                    notes: file.notes,
                    s3Url: '',
                    uploadedAt: new Date().toISOString(),
                }))
            ]
        }));

        if (newPendingFiles.length > 0) {
            const latestFile = newPendingFiles[newPendingFiles.length - 1].file; // Get the last file

            const reader = new FileReader();
            reader.onload = (e) => {
                const target = e.target as FileReader;

                if (target && target.result) {
                    if (latestFile.type.includes('image')) {
                        setFilePreview(<img src={target.result as string} alt="Image preview" />);
                    } else if (latestFile.type.includes('pdf')) {
                        setFilePreview(<iframe src={target.result as string} width="100%" height="500px" title="PDF preview"></iframe>);
                    } else {
                        setFilePreview('Preview not available for this file type');
                    }
                }
            };

            if (latestFile.type.includes('image') || latestFile.type.includes('pdf')) {
                reader.readAsDataURL(latestFile); // For images and PDFs
            } else {
                setFilePreview('Preview not available for this file type');
            }
        } else if (updatedExistingFiles.length === 0 && newPendingFiles.length === 0) {
            // If no files are attached, remove the preview
            setFilePreview(null);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            let createdOrUpdatedId: string;
            if (isEditMode && id) {
                await FileHandler.deleteFiles(deletedFilesList, id);
                const uploadedFiles = await FileHandler.uploadFilesWithId(pendingFiles, id);
                const updatedData = { ...rawMaterial, files: [...existingFiles, ...uploadedFiles] };
                await ApiService.updateRawMaterial(id, updatedData);
                createdOrUpdatedId = id;
            } else {
                // Create new material
                const uploadedFiles = await FileHandler.uploadFilesWithoutId(pendingFiles);
                const newData = { ...rawMaterial, files: uploadedFiles };
                const createdData = await ApiService.createRawMaterial(newData);
                createdOrUpdatedId = createdData.id;
                await FileHandler.moveFiles(uploadedFiles, createdData.id);
            }
            // Navigate after create or update
            if (createdOrUpdatedId) {
                navigate(ROUTES.RAW_MATERIALS_DETAIL(createdOrUpdatedId));
            }
        } catch (error) {
            setError(id ? 'Failed to update raw material' : 'Failed to create raw material');
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value, type } = e.target;
        const updatedValue = type === 'number' && value ? Number(value) : value;

        setRawMaterial((prevData) => ({
            ...prevData,
            [name]: updatedValue
        }));
    };


    const renderFieldsForRawMaterial = () => {
        const fields = getFieldsForRawMaterial();
        return fields.map((field) => (
            <FormField
                key={field.name}
                label={t(`rawMaterialDataField.${field.name}`)}
                name={field.name}
                value={(rawMaterial as any)[field.name] || ''}
                onChange={handleChange}
                type={field.type}
                explanation={t(`rawMaterialDataExplanation.${field.name}`)}
                required={field.required}
            />
        ));
    };

    return (
        <div>
            <Header />
            <BackButton route={ROUTES.RAW_MATERIALS} />
            <ErrorModal message={error} onClose={() => setError(null)} />
            <div className="form-page">
                <h1 className="form-title">{id ? 'Update Raw Material' : 'Add New Raw Material'}</h1>
                <div className="form-content">
                    <form onSubmit={handleSubmit} className="raw-material-form">
                        {renderFieldsForRawMaterial()}
                        <button type="submit" className="submit-button">
                            {id ? 'Update' : 'Create'}
                        </button>
                    </form>
                    <div className="file-upload-preview">
                        <label className="field-label">Related Files</label>
                        <FileUpload
                            onFilesChange={handleFileChange}
                            existingFiles={existingFiles}
                            onDeleteExistingFile={(fileId) => {
                                setDeletedFilesList((prev) => [...prev, existingFiles.find((file) => file.id === fileId)]);
                                setExistingFiles((prev) => prev.filter((file) => file.id !== fileId));
                            }}
                        />
                        {filePreview && (
                            <div className="file-preview">
                                <h4>File Preview</h4>
                                {filePreview}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RawMaterialForm;
